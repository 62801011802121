import React, { memo, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../assets/sass/uiV3/pages/webNotAvailable/_webNotAvailableLanding.scss';
import { Button } from 'react-bootstrap';
import playStore from './play-store.svg';
import appStore from './app-store.svg';
import iphoneFrame from './iphoneFrame.png';
import queryString from 'query-string';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import SwiperCore from 'swiper';
import { EffectFade, Mousewheel, Thumbs, Navigation, Autoplay } from 'swiper/modules';
import { isMobile } from 'react-device-detect';
import { Navigate, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../Store/actions/login.action';
import { resetStore } from '../../../Store/actions';
import { deleteCaches } from '../../../common/common';
import { ClirnetFullLogo } from '../../../components/icons';
SwiperCore.use([EffectFade, Mousewheel, Thumbs, Navigation, Autoplay]);
const AppRegister = ({ from = {} }) => {
  console.log('from', from);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.common.user_details);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (from?.pathname) {
      sessionStorage.setItem('app-register-from', JSON.stringify(from));
      navigate('/fallback');
    }
  }, []);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const onDynamicLinkClick = async () => {
    setLoading(true);
    const location = sessionStorage.getItem('app-register-from')
      ? JSON.parse(sessionStorage.getItem('app-register-from'))
      : {};
    let id = 0,
      path = 'dashboard';
    if (location) {
      // console.log('ID', queryString.parse(location.search));
      if (location.search) {
        id = queryString.parse(location.search).id;
      } else if (location.pathname?.split('/')?.length > 0) {
        let splitted = location.pathname?.split('/');
        let indexOfDetails = splitted?.findIndex((s) => s == 'detail' || s == 'details');
        if (indexOfDetails > -1) {
          if (splitted[indexOfDetails + 1]) {
            id = splitted[indexOfDetails + 1];
          }
        }
      }
      if (location?.pathname) {
        if (location?.pathname?.toLowerCase().includes('medwiki')) {
          path = 'medwiki';
        }
        if (location?.pathname?.toLowerCase().includes('session')) {
          path = 'session';
        }
        if (location?.pathname?.toLowerCase().includes('grandround')) {
          path = 'gr';
        }
        if (location?.pathname?.toLowerCase().includes('channel')) {
          path = 'channel';
        }
        if (location?.pathname?.toLowerCase().includes('clinicalvideo')) {
          path = 'archived_video';
        }
        if (location?.pathname?.toLowerCase().includes('ebook')) {
          path = 'epub';
        }
        if (location?.pathname?.toLowerCase().includes('spq')) {
          path = 'survey';
        }
        if (location?.pathname?.toLowerCase().includes('training')) {
          path = 'training';
        }
        if (location?.pathname?.toLowerCase().includes('observership/registration')) {
          path = 'claim_mentorship';
        }
      }
    }
    try {
      toast.info('Please wait...');
      let response = await axiosInstance.get(
        `settings/get_build_dynamic_link?type=${path}&type_id=${id ? id : 0}`
      );
      let tkUrl = response?.data?.data?.tk_url;
      if (tkUrl) {
        setLoading(false);
        window.location.href = tkUrl;
      } else {
        window.location.href = 'https://clirnet.page.link/Uo2c';
      }
    } catch (error) {
      console.log('error', error);
      window.location.href = 'https://clirnet.page.link/Uo2c';
    }
  };
  const images = [
    'https://assets.clirnet.com/defaultContentImage/images/medwikiScreenshot.png',
    'https://assets.clirnet.com/defaultContentImage/images/aidaScreenshot.png',
    'https://assets.clirnet.com/defaultContentImage/images/livecmeScreenshot.png',
    'https://assets.clirnet.com/defaultContentImage/images/courseScreenshot.png',
    'https://assets.clirnet.com/defaultContentImage/images/spqScreenshot.png'
  ];
  const logout = () => {
    window.location.href = '/';
    dispatch(logoutUser());
    dispatch(resetStore());
    deleteCaches();
  };
  if (userDetails?.env === 'IN') {
    return <Navigate to="/dashboard" />;
  }
  return (
    <>
      <div className="position-relative webNotAvailableLanding">
        <Swiper
          className="webNotAvailableLanding__mainslide"
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          modules={[Navigation, Thumbs, Autoplay, Mousewheel]}
          effect={'fade'}
          mousewheel={true}
          navigation={false}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false
          }}
          breakpoints={{
            767: {
              navigation: true
            }
          }}
        >
          {images.map((img) => (
            <SwiperSlide
              className="position-relative d-flex vh-100 flex-md-nowrap flex-wrap flex-column-reverse flex-md-row"
              key={img}
            >
              <div className="webNotAvailableLanding__left d-flex align-items-center bg-light p-lg-50 p-5 text-md-start text-center justify-content-center justify-content-md-start">
                <div>
                  <ClirnetFullLogo className="webNotAvailableLanding__left__logo mb-20" />
                  <h1 className="webNotAvailableLanding__left__h1--ttl text-uppercase text-black fw-normal">
                    <span className="fs-36">Join World's</span>
                    <span className="d-block fs-30 ">
                      Fastest Growing <span className="fw-bold">Community</span>
                    </span>
                    <span className="d-block webNotAvailableLanding__left__h1--ttl__sub">
                      Of <span className="fw-bold">Doctors</span> Today!
                    </span>
                  </h1>
                  <div className="webNotAvailableLanding__left__slide-content transition-base mt-3 d-none d-lg-block">
                    {/* <h2 className="fs-3 text-primary d-inline-flex align-items-center gap-2">
                      <i className="flaticon-medwiki fs-2"></i>
                      <span>
                        Medwiki<sup>®</sup>
                      </span>
                    </h2> */}
                    <p className="fs-16">
                      CLIRNET serves the needs of thousands of doctors every day by providing them
                      with digital tools and services which equips them to deliver last mile patient
                      care which is accessible, affordable and equitable.
                    </p>
                  </div>
                  <div className="d-flex gap-sm-4 gap-3 mt-20">
                    <Button
                      type="button"
                      disabled={loading}
                      onClick={onDynamicLinkClick}
                      variant={`${isMobile ? 'white' : 'black'}`}
                      className="rounded-3 px-5 fs-3 d-flex justify-content-center align-items-center gap-2 gap-sm-3"
                    >
                      <img src={playStore} alt="play store" className="icon-size-32" />
                      Play Store
                    </Button>
                    <Button
                      disabled={loading}
                      type="button"
                      onClick={onDynamicLinkClick}
                      variant={`${isMobile ? 'white' : 'black'}`}
                      className="rounded-3 px-5 fs-3 d-flex justify-content-center align-items-center gap-2 gap-sm-3 webNotAvailableLanding__appstoreBtn"
                    >
                      <img src={appStore} alt="app store" className="icon-size-32" />
                      App Store
                    </Button>
                  </div>
                  <h5 className="mt-4">**Download the app Now! Available for iOS and Android.</h5>
                  <Button
                    variant="dark"
                    className={
                      'rounded-pill px-5 fs-3 d-flex justify-content-center align-items-center gap-2 gap-sm-3 py-3 mt-4 ' +
                      (isMobile ? 'mx-auto' : '')
                    }
                    onClick={logout}
                  >
                    <i className="flaticon-logout fs-3"></i> Log out
                  </Button>
                </div>
              </div>
              <div className="webNotAvailableLanding__right flex-grow-1 ms-auto d-flex align-items-md-center align-items-start justify-content-center p-sm-50 p-20">
                <div className="webNotAvailableLanding__right__mobile__align">
                  <div className="webNotAvailableLanding__right__mobile-slider position-relative">
                    <img
                      src={img}
                      alt="mobile frame"
                      loading="eager"
                      className="position-absolute top-0 start-0 w-100 h-100 z-1"
                    />
                    {/* <div className="webNotAvailableLanding__right__screenshot w-100 h-100 overflow-hidden">
                      <img src={img} className="w-100 h-100 transition-base" alt="app screenshot" />
                    </div> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="position-absolute top-0 z-3 h-100 d-lg-flex d-none align-items-center justify-content-center webNotAvailableLanding__thumbSlideContainer">
          <div className="webNotAvailableLanding__thumbSlideAlign">
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={0}
              slidesPerView={5}
              // centeredSlides={true}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[Thumbs]}
              direction={'vertical'}
              // loop={true}
              className="webNotAvailableLanding__thumbSlide"
            >
              <SwiperSlide
                className="webNotAvailableLanding__thumbItem h-auto w-100 d-flex justify-content-end"
                key={'MedWiki'}
              >
                <div className="d-flex align-items-center gap-3 fs-3 webNotAvailableLanding__thumbItem__in rounded-pill p-3 ps-5">
                  <span className="fw-bold text-nowrap">
                    MedWiki<sub>®</sub>
                  </span>{' '}
                  <div className="rounded-pill icon-size-60 d-inline-flex align-items-center justify-content-center webNotAvailableLanding__thumbItem__icon">
                    <i className="flaticon-medwiki fs-30"></i>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="webNotAvailableLanding__thumbItem h-auto w-100 d-flex justify-content-end"
                key={'AiDA'}
              >
                <div className="d-flex align-items-center gap-3 fs-3 webNotAvailableLanding__thumbItem__in rounded-pill p-3 ps-5">
                  <span className="fw-bold text-nowrap">AiDA</span>{' '}
                  <div className="rounded-pill icon-size-60 d-inline-flex align-items-center justify-content-center webNotAvailableLanding__thumbItem__icon">
                    <i className="flaticon-ddx fs-2"></i>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="webNotAvailableLanding__thumbItem h-auto w-100 d-flex justify-content-end"
                key={'CME'}
              >
                <div className="d-flex align-items-center gap-3 fs-3 webNotAvailableLanding__thumbItem__in rounded-pill p-3 ps-5">
                  <span className="fw-bold text-nowrap">Live CME</span>{' '}
                  <div className="rounded-pill icon-size-60 d-inline-flex align-items-center justify-content-center webNotAvailableLanding__thumbItem__icon">
                    <i className="flaticon-livecme fs-30"></i>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="webNotAvailableLanding__thumbItem h-auto w-100 d-flex justify-content-end"
                key={'Courses'}
              >
                <div className="d-flex align-items-center gap-3 fs-3 webNotAvailableLanding__thumbItem__in rounded-pill p-3 ps-5">
                  <span className="fw-bold text-nowrap">Certified Courses</span>{' '}
                  <div className="rounded-pill icon-size-60 d-inline-flex align-items-center justify-content-center webNotAvailableLanding__thumbItem__icon">
                    <i className="flaticon-training fs-30"></i>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="webNotAvailableLanding__thumbItem h-auto w-100 d-flex justify-content-end"
                key={'Opinions'}
              >
                <div className="d-flex align-items-center gap-3 fs-3 webNotAvailableLanding__thumbItem__in rounded-pill p-3 ps-5">
                  <span className="fw-bold text-nowrap">Opinions</span>{' '}
                  <div className="rounded-pill icon-size-60 d-inline-flex align-items-center justify-content-center webNotAvailableLanding__thumbItem__icon">
                    <i className="flaticon-polls fs-30"></i>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(AppRegister);
