import React, { lazy } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import DetailsPageLayout from '../../layout/DetailsPageLayout';
import SpecialityLayoutOldPage from '../../layout/SpecialityLayoutOldPage';
import SpecialityDetailsLayout from '../../layout/SpecialityDetailsLayout';
import SpecialityLayout from '../../layout/SpecialityLayout';
import SpecialityDefaultLayout from '../../layout/uiv3/SpecialityDefaultLayout';
import { Row } from 'react-bootstrap';
import Loadable from '../Loadable';

const Dashboard = Loadable({
  loader: () => import('../../../src/pages/dashboard/Dashboard')
});

const EbookListing = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookListing')
});
const EbookDetails = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookDetails')
});
const EpaperDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/epaper/EpaperDetails')
});
const Vault = Loadable({
  loader: () => import('../../../src/pages/vault/Vault')
});

const HelpAndSupport = Loadable({
  loader: () => import('../../../src/pages/helpAndSupport/HelpAndSupport')
});
const MedWikiListing = Loadable({
  loader: () => import('../../../src/pages/medwiki/MedwikiListing')
});
const MedwikiPopular = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiLandingComponents/MedwikiPopular')
});

const CpdReview = Loadable({
  loader: () => import('../../../src/pages/userProfile/CpdReview')
});

const GrandRoundListing = Loadable({
  loader: () => import('../../../src/pages/grandRounds/GrandRoundListing')
});

const DiscussRefer = Loadable({
  loader: () => import('../../../src/pages/discussRefer/DiscussRefer')
});
const HospitalProfile = Loadable({
  loader: () => import('../../../src/pages/discussRefer/hospitalProfile')
});
const DiscussForm = Loadable({
  loader: () => import('../../../src/pages/discussRefer/DiscussForm')
});
const CaseDetails = Loadable({
  loader: () => import('../../../src/pages/discussRefer/CaseDetails')
});
const ReInitiateRefer = Loadable({
  loader: () => import('../../../src/pages/discussRefer/ReInitiateRefer')
});
const Activities = Loadable({
  loader: () => import('../../../src/pages/discussRefer/Activities')
});
const ClinicalVideoList = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoList')
});
const ClinicalVideoDetails = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalDetails')
});
const ClinicalVideoDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/clinicalVideo/ClinicalVideoDetails')
});
const ChannelLanding = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelLanding')
});
const ChannelManage = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelManage')
});
const ChannelProfile = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelProfile')
});
const TopRatedMedwikiListing = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/TopRatedMedwikiListing')
});
const ChannelSuggestion = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelSugesstion')
});
const ChannelLandingV2 = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelLandingV2')
});
const ChannelProfileV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/channel/ChannelProfile')
});

const ReferThankYou = Loadable({
  loader: () => import('../../../src/pages/discussRefer/ReferThankyou')
});
const SPQ = Loadable({
  loader: () => import('../../../src/pages/spq/spq')
});
const UserPoint = Loadable({
  loader: () => import('../../../src/pages/spq/UserPoint')
});
const SPQreview = Loadable({
  loader: () => import('../../../src/pages/spq/SPQreview')
});

const SessionListing = Loadable({
  loader: () => import('../../../src/pages/session/SessionListing')
});
const SessionWaitingRoom = Loadable({
  loader: () => import('../../../src/pages/session/SessionWaitingRoom')
});

const AllSessionList = Loadable({
  loader: () => import('../../../src/pages/session/AllSessionList')
});
const LiveSession = Loadable({
  loader: () => import('../../../src/pages/session/LiveSession')
});
const ElectionLandinng = Loadable({
  loader: () => import('../../../src/pages/election/ElectionLanding')
});
const CandidateSelection = Loadable({
  loader: () => import('../../../src/pages/election/CandidateSelection')
});
const VotingSummary = Loadable({
  loader: () => import('../../../src/pages/election/VotingSummary')
});
const MobileVerification = Loadable({
  loader: () => import('../../../src/pages/election/MobileVerification')
});

const Notification = Loadable({
  loader: () => import('../../../src/pages/notification/Notification')
});
const DoctubeLanding = Loadable({
  loader: () => import('../../../src/pages/doctubeLanding/DoctubeLanding')
});

const DoctubeListingVideos = Loadable({
  loader: () =>
    import(
      '../../../src/pages/doctube/doctubeProfileManagement/components/listing/DoctubeListingVideos'
    )
});
const ProfileSteps = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/ProfileSteps')
});
const DoctubeDashboard = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeDashboard')
});
const DoctubeVideoUpload = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeVideoUpload')
});
const DoctubeManagePlaylist = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeManagePlaylist')
});

const ShareLoginNew = Loadable({
  loader: () => import('../../../src/pages/shareLogin/ShareLoginNew')
});

const ClinicalVideoLanding = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoLanding')
});

const SearchResult = Loadable({
  loader: () => import('../../../src/pages/Search/SearchResult')
});
const SearchResultV2 = Loadable({
  loader: () => import('../../../src/pages/Search/SearchV3')
});
const SearchList = Loadable({
  loader: () => import('../../../src/pages/Search/SearchList')
});
const TelemedLite = Loadable({
  loader: () => import('../../../src/pages/telemedLite/TelemedLite')
});

const UploadCertificate = Loadable({
  loader: () => import('../../../src/pages/certificates/UploadCertificate')
});
const CertificatesListing = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificatesListing')
});

const ProfileVideos = Loadable({
  loader: () => import('../../../src/pages/userProfile/ProfileVideos')
});

const GRlanding = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingLanding')
});
const GRlisting = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingListing')
});
const GRcover = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingCover')
});
const GRcontent = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingContent')
});
const GRsummary = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingSummary')
});
const MedwikiLandingV2 = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiLandingV2')
});
const Demo = Loadable({
  loader: () => import('../../../src/pages/Demo')
});
const MedwikiDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwiki/MedwikiDetails')
});
const MedwikiDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiDetailsV2')
});

const AdsPlayer = Loadable({
  loader: () => import('../../components/CustomLibraries/AdsPlayer')
});

const PollDetails = Loadable({
  loader: () => import('../../../src/pages/spq/PollDetails')
});

const CertificateDetails = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificateDetails')
});

const QueryListing = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/QueryListing')
});

const UpcomingSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/UpcomingSessionsList')
});

const PreviousSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/PrviousSession')
});

const SpeakerProfile = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/SpeakerProfile')
});

const SPQquestion = Loadable({
  loader: () => import('../../../src/pages/spq/SPQquestion')
});

const SPQsubmitSuccessfully = Loadable({
  loader: () => import('../../../src/pages/spq/SPQsubmitSuccessfully')
});

const MedwikiLanding = Loadable({
  loader: () => import('../../../src/pages/medwiki/MedwikiLanding')
});

const SPQcompleted = Loadable({
  loader: () => import('../../../src/pages/spq/SPQcompleted')
});
const SPQlanding = Loadable({
  loader: () => import('../../../src/pages/spq/SPQlanding')
});

// const SessionLanding = Loadable(() => import("../../src/pages/session/SessionLanding"));
// const SessionDetails = Loadable({
//   loader: () => import('../../../src/pages/session/SessionDetails'),
//
// });
const SessionDetailsOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionDetailsOutlet')
});
const LiveSessionOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/LiveSessionOutlet')
});

const Photobooth = Loadable({
  loader: () => import('../../../src/pages/multidaySession/PhotoBooth')
});
// const ConnectDetails = Loadable({
//   loader: () => import('../../src/pages/connect/ConnectDetails'),
//
// });

const DoctubePlaylistDetails = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubePlaylistDetails')
});

const GrandRoundDetails = Loadable({
  loader: () => import('../../../src/pages/grandRounds/GrandRoundDetails')
});

const SPQdetails = Loadable({
  loader: () => import('../../../src/pages/spq/SPQdetails')
});
const SpecialityDashboard = Loadable({
  loader: () => import('../../../src/pages/uiv3/dashboard/Dashboard')
});

const Profile = Loadable({
  loader: () => import('../../../src/pages/uiv3/profile/Profile')
});

const UserProfile = Loadable({
  loader: () => import('../../../src/pages/userProfile/UserProfileBkp.jsx')
});
const Ddx = Loadable({
  loader: () => import('../../../src/pages/askClirnet/DiagnosisHelp')
});

const MostReadContent = Loadable({
  loader: () => import('../../../src/pages/mostReadContent/MostReadContent')
});

const SpecialityLanding = Loadable({
  loader: () => import('../../../src/pages/speciality/SpecialityLanding')
});

const AllSpecialities = Loadable({
  loader: () => import('../../../src/pages/uiv3/AllSpeciality')
});

const MostCommentedMedwikiListing = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MostCommentedMedwikiListing')
});
const TagSearch = Loadable({
  loader: () => import('../../../src/pages/Search/TagSearch')
});

export const layoutedRoutes = [
  {
    layout: <DefaultLayout />,
    SpecialityLayout: <SpecialityLayoutOldPage />,
    subpath: [
      {
        path: '/DifferentialDiagnosis',
        component: <Ddx />,
        module: 'aidda'
      },
      {
        path: '/MostRead',
        component: <MostReadContent />,
        specialityComponent: <MostReadContent />,
        module: 'speciality'
      },
      {
        path: '/Vault',
        component: <Vault />,
        module: 'speciality'
      },
      {
        path: '/HelpAndSupport',
        component: <HelpAndSupport />,
        module: 'generic'
      },
      {
        path: '/SearchResult',
        component: <SearchResultV2 />,
        module: 'generic'
      },
      {
        path: '/search/tag',
        component: <TagSearch />,
        module: 'generic'
      },
      {
        path: '/SearchList',
        component: <SearchList />,
        module: 'generic'
      },

      {
        path: '/UploadCertificate',
        component: <UploadCertificate />,
        module: 'generic'
      },
      {
        path: '/CertificatesListing',
        component: <CertificatesListing />,
        module: 'generic'
      },
      {
        path: '/CpdReview',
        component: <CpdReview />,
        module: 'generic'
      },
      {
        path: '/UpcomingSessionList',
        component: <UpcomingSessionList />,
        module: 'generic'
      },
      {
        path: '/PreviousSessionList',
        component: <PreviousSessionList />,
        module: 'generic'
      },
      {
        path: '/QueryListing',
        component: <QueryListing />,
        module: 'generic'
      },
      {
        path: '/SPQquestion',
        component: <SPQquestion />,
        module: 'spq'
      },
      {
        path: '/SPQreview',
        component: <SPQreview />,
        module: 'spq'
      },
      {
        path: '/SPQsubmitSuccessfully',
        component: <SPQsubmitSuccessfully />,
        module: 'spq'
      },
      {
        path: '/SPQperformed',
        component: <SPQcompleted />,
        module: 'spq'
      },
      {
        path: '/Sessions',
        component: <SessionListing />,
        module: 'session'
      },
      {
        path: '/AllSessionList',
        component: <AllSessionList />,
        module: 'session'
      },

      {
        path: '/Ebook',
        component: <EbookListing />,
        module: 'epub'
      },
      {
        path: '/MedwikiListing',
        component: <MedWikiListing />,
        module: 'medwiki'
      },
      {
        path: '/MostCommentedMedwikiListing',
        component: <MostCommentedMedwikiListing />,
        module: 'medwiki'
      },
      {
        path: '/MedwikiPopular',
        component: <MedWikiListing />,
        module: 'medwiki'
      },
      {
        path: '/GrandRound',
        component: <GrandRoundListing />,
        module: 'gr'
      },
      {
        path: '/DiscussRefer',
        component: <DiscussRefer />,
        module: 'dnr'
      },
      {
        path: '/HospitalProfile',
        component: <HospitalProfile />,
        module: 'generic'
      },
      {
        path: '/DiscussForm',
        component: <DiscussForm />,
        module: 'dnr'
      },
      {
        path: '/ReferForm',
        component: <DiscussForm />,
        module: 'dnr'
      },
      {
        path: '/Activities',
        component: <Activities />,
        module: 'speciality'
      },
      {
        path: '/ReInitiateRefer',
        component: <ReInitiateRefer />,
        module: 'dnr'
      },
      {
        path: '/ReferThankyou',
        component: <ReferThankYou />,
        module: 'dnr'
      },
      {
        path: '/ClinicalVideos',
        component: <ClinicalVideoList />,
        module: 'clinicalVideo'
      },
      {
        path: '/Medwiki',
        component: <MedwikiLandingV2 />,
        module: 'medwiki'
      },
      {
        path: '/Demo',
        component: <Demo />,
        module: 'generic'
      },
      {
        path: '/ClinicalVideoLanding',
        component: <ClinicalVideoLanding />,
        module: 'clinicalVideo'
      },
      {
        path: '/Channel',
        component: <ChannelLandingV2 />,
        module: 'channel'
      },
      {
        path: '/ManageChannel',
        component: <ChannelManage />,
        module: 'channel'
      },
      {
        path: '/ChannelSuggestion',
        component: <ChannelSuggestion />,
        module: 'channel'
      },
      {
        path: '/TopRatedMedwikiListing',
        component: <TopRatedMedwikiListing />,
        module: 'medwiki'
      },
      {
        path: '/TeleMed',
        component: <TelemedLite />,
        module: 'telemed'
      },
      {
        path: '/SPQlanding',
        component: <SPQlanding />,
        module: 'spq'
      },
      {
        path: '/UserPoint',
        component: <UserPoint />,
        module: 'generic'
      },
      {
        path: '/ElectionLanding',
        component: <ElectionLandinng />,
        module: 'election'
      },
      {
        path: '/CandidateSelection',
        component: <CandidateSelection />,
        module: 'election'
      },
      {
        path: '/VotingSummary',
        component: <VotingSummary />,
        module: 'election'
      },
      {
        path: '/MobileVerification',
        component: <MobileVerification />,
        module: 'generic'
      },
      {
        path: '/AdsPlayer',
        component: <AdsPlayer />,
        module: 'generic'
      },
      {
        path: '/ProfileVideos',
        component: <ProfileVideos />,
        module: 'generic'
      },
      {
        path: '/TrainingLanding',
        component: <GRlanding />,
        module: 'courses'
      },

      {
        path: '/TrainingSummary',
        component: <GRsummary />,
        module: 'courses'
      },
      {
        path: '/Notification',
        component: <Notification />,
        module: 'generic'
      },
      {
        path: '/Doctube',
        component: <DoctubeLanding />,
        module: 'doctube'
      },

      {
        path: '/DoctubeListingVideos',
        component: <DoctubeListingVideos />,
        module: 'doctube'
      },
      {
        path: '/ProfileSteps',
        component: <ProfileSteps />,
        module: 'doctube'
      },
      {
        path: '/DoctubeDashboard',
        component: <DoctubeDashboard key="doctubeDashboard" />,
        module: 'doctube'
      },
      {
        path: '/DoctubeAnalytics',
        component: <DoctubeDashboard key="doctubeAnalytics" />,
        module: 'doctube'
      },
      {
        path: '/DoctubeVideoUpload',
        component: <DoctubeVideoUpload />,
        module: 'doctube'
      },
      {
        path: '/DoctubeManagePlaylist',
        component: <DoctubeManagePlaylist />,
        module: 'doctube'
      },
      {
        path: '/SPQ',
        component: <SPQ />,
        module: 'spq'
      },
      {
        path: '/TrainingListing',
        component: <GRlisting />,
        module: 'courses'
      },
      {
        path: '/Speciality',
        component: <SpecialityLanding />,
        module: 'speciality'
      }
    ]
  },
  {
    layout: <DefaultLayout />,
    SpecialityLayout: <SpecialityLayout />,
    subpath: [
      {
        path: '/dashboard',
        component: <Dashboard />,
        specialityComponent: <SpecialityDashboard />,
        module: 'generic'
      },
      {
        path: '/UserProfile',
        component: <UserProfile />,
        specialityComponent: <Profile />,
        module: 'generic'
      },
      {
        path: '/AllSpecialities',
        component: <AllSpecialities />,
        module: 'speciality'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDetailsLayout />,
    subpath: [
      {
        path: '/TrainingContent',
        component: <GRcontent />,
        module: 'courses'
      },

      // {
      //   path: '/MedwikiDetails',
      //   component: <MedwikiDetailsV2 />,
      //   module: 'medwiki'
      // },
      {
        path: '/SpeakerProfile',
        component: <SpeakerProfile />,
        module: 'generic'
      },
      {
        path: '/CertificateDetails',
        component: <CertificateDetails />,
        module: 'generic'
      },
      {
        path: '/SPQdetails',
        component: <SPQdetails />,
        module: 'spq'
      },
      {
        path: '/PollDetails',
        component: <PollDetails />,
        module: 'spq'
      },
      // {
      //   path: '/SessionDetails',
      //   component: <SessionDetails />
      // },
      {
        path: '/SessionDetails',
        component: <SessionDetailsOutlet />,
        module: 'session'
      },
      // {
      //   path: '/ClinicalVideoDetails',
      //   component: <ClinicalVideoDetails />,
      //   module: 'clinicalVideo'
      // },
      {
        path: '/LiveSession',
        component: <LiveSessionOutlet />,
        module: 'session'
      },
      // {
      //   path: '/SessionWaitingroom',
      //   component: <SessionWaitingroomOutlet />,
      //   module: 'session'
      // },
      {
        path: '/photobooth',
        component: <Photobooth />,
        module: 'session'
      },
      {
        path: '/CaseDetails',
        component: <CaseDetails />,
        module: 'generic'
      },
      {
        path: '/GrandRoundDetails',
        component: <GrandRoundDetails />,
        module: 'gr'
      },

      {
        path: '/DoctubePlaylistDetails',
        component: <DoctubePlaylistDetails />,
        module: 'doctube'
      },

      {
        path: '/TrainingCover',
        component: <GRcover />,
        module: 'gr'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDefaultLayout />,
    subpath: [
      {
        path: '/MedwikiDetails',
        component: <MedwikiDetailsV2 />,
        specialityComponent: <MedwikiDetails />,
        module: 'medwiki'
      },
      {
        path: '/ChannelProfile',
        specialityComponent: <ChannelProfileV2 isSpecialityLayout />,
        component: (
          <div className="w-100 d-flex flex-column position-relative speciality-portal--defaultLayout">
            <div className="container-fluid">
              <Row className="align-items-start flex-nowrap">
                <ChannelProfileV2 />
              </Row>
            </div>
          </div>
        ),
        module: 'channel'
      },
      {
        path: '/EbookDetails',
        component: <EbookDetails />,
        specialityComponent: <EpaperDetails />,
        module: 'epub'
      },
      {
        path: '/ClinicalVideoDetails',
        component: <ClinicalVideoDetails />,
        specialityComponent: <ClinicalVideoDetailsV2 />,
        module: 'clinicalVideo'
      }
    ]
  }
];
