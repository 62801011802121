export const getPlatForm = () => {
  var OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
  if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
  if (navigator.appVersion.indexOf('IOS') != -1) OSName = 'IOS';
  if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
  if (navigator.appVersion.indexOf('Android') != -1) OSName = 'Android';
  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //   var device_type = 'Mobile';
  // } else {
  //   var device_type = 'PC';
  // }
  return OSName;
};
