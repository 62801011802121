import { useInfiniteQuery, useQuery, useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Store/actions/login.action';
import axiosInstance from '../../Store/helper/axiosInstance';
import {
  DASHBOARD_ALL_DATA,
  DASHBOARD_CERT_COUNT,
  DASHBOARD_DOCTOR_VOICE,
  DASHBOARD_RECENT,
  DASHBOARD_RESERVED_CME,
  SPECIALITY_FEED_DATA,
  TRENDING_TOPIC,
  DASHBOARD_TRENDING_CONTENT,
  DASHBOARD_MOSTREAD_CONTENT,
  MOST_READ_FEED_DATA
} from '../QueryKeys/dashboard.key';
import nodeServiceInstance from '../../Store/helper/nodeServiceInstance';
import axios from 'axios';
import { isMobileApp } from '../../common/common';
const staleTime = 300000;
const cacheTime = 600000;
export const useDashboardDoctorVoice = (onSuccess, onError, enabled = true) => {
  return useQuery(DASHBOARD_DOCTOR_VOICE, () => axiosInstance.get('dashboard/poll?from=0&to=10'), {
    enabled: !!enabled,
    onSuccess,
    onError,
    select: (response) => response.data.data,

    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};

export const useDashboardAllData = (onSuccess, onError) => {
  const dispatch = useDispatch();
  return useQuery(DASHBOARD_ALL_DATA, () => axiosInstance.get('dashboard/data?from=0&to=8'), {
    onSuccess,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.log('error_LOGIN_AGAIN', err);
      if (err == 'loginAgain') {
        dispatch(logoutUser());
      }
    },
    select: (response) => {
      return {
        sessionSlider: response.data?.data?.filter((_d) => _d.trending_type == 'session') ?? [],
        trendingSurvey: response.data?.data?.filter((_d) => _d.trending_type == 'survey') ?? [],
        trendingGr: response.data?.data?.filter((_d) => _d.trending_type == 'gr') ?? [],
        medwikiList: response.data?.data?.filter((_d) => _d.trending_type == 'comp') ?? [],
        clinicalVideos:
          response.data?.data?.filter((_d) => _d.trending_type == 'video_archive') ?? []
      };
    },
    staleTime: staleTime,
    cacheTime: cacheTime
  });
};
export const useDashboardCertCount = (onSuccess, onError) => {
  return useQuery(DASHBOARD_CERT_COUNT, () => axiosInstance.get('certificate/count'), {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    select: (response) => response.data.data,

    cacheTime: cacheTime
  });
};
export const useDashboardReservedCount = (onSuccess, onError) => {
  return useQuery(
    DASHBOARD_RESERVED_CME,
    () => axiosInstance.get('knwlgmastersessionnew/upcomingsessionreserved'),
    {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
      select: (response) => response?.data?.data,

      cacheTime: cacheTime
    }
  );
};
export const useDashboardRecent = (inView) => {
  return useInfiniteQuery(
    DASHBOARD_RECENT,
    ({ pageParam = 0 }) => axiosInstance.get(`dashboard/trending?from=${pageParam}&to=5&type=all`),
    {
      refetchOnWindowFocus: false,
      enabled: !!inView,
      staleTime: staleTime,
      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          // console.log(allPages.length);
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useGetTrendingTopic = (speciality = 0, onSuccess, onError) => {
  return useQuery(
    [TRENDING_TOPIC, speciality],
    () => axiosInstance.get(`dashboard/get_trending_topic?speciality_id=${speciality.toString()}`),
    {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
      enabled: speciality == 0 || !!speciality,
      select: (response) => response.data.data,
      cacheTime: cacheTime
    }
  );
};

export const useGetFeedData = (speciality, type) => {
  let cmeItemsArray = ['all cme', 'upcoming', 'reserved', 'recorded'];
  let parent_type = cmeItemsArray.includes(type) ? 'cme' : type;
  let sub_type = cmeItemsArray.includes(type)
    ? type == 'all cme'
      ? 'normal'
      : type
    : type == 'cme'
    ? 'normal'
    : '';
  return useInfiniteQuery(
    [SPECIALITY_FEED_DATA, speciality, type],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `dashboard/dataMl?from=${pageParam}&to=5&speciality=${speciality}&type=${parent_type}${
          sub_type ? `&subtype=${sub_type}` : ''
        }${isMobileApp() ? '&convert=1' : ''}`
      ),

    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length >= 5) {
          // console.log(allPages.length);
          return allPages.length * 5;
        } else {
          return undefined;
        }
      },
      staleTime: staleTime,
      cacheTime: cacheTime
      // enabled: !!speciality || speciality != 0
    }
  );
};

// export const useGetFeedData = (speciality, type) => {
//   console.log('type=>', type);
//   let cmeItemsArray = ['all cme', 'upcoming', 'reserved', 'recorded'];
//   let parent_type = cmeItemsArray.includes(type) ? 'cme' : type;
//   let sub_type = cmeItemsArray.includes(type)
//     ? type == 'all cme'
//       ? 'normal'
//       : type
//     : type == 'cme'
//     ? 'normal'
//     : '';
//   return useInfiniteQuery(
//     [SPECIALITY_FEED_DATA, speciality, type],
//     ({ pageParam = 0 }) =>
//       nodeServiceInstance.get(
//         `specialist/get-specialist-data?from=${pageParam}&to=5&speciality=${speciality}&type=${parent_type}${
//           sub_type != '' ? `&subtype=${sub_type}` : ''
//         }`
//       ),
//     // nodeServiceInstance.post(`specialist/get-specialist-data`, bodyJson),
//     {
//       refetchOnWindowFocus: false,
//       getNextPageParam: (lastPage, allPages) => {
//         if (lastPage?.data?.data?.length >= 5) {
//           // console.log(allPages.length);
//           return allPages.length * 5;
//         } else {
//           return undefined;
//         }
//       },
//       staleTime: staleTime,
//       cacheTime: cacheTime,
//       enabled: !!speciality || speciality != 0
//     }
//   );
// };

export const useGetMostReadData = (speciality, content_type) => {
  console.log('speciality=>', speciality);
  let payloadContent = [];
  switch (content_type) {
    case 'read':
      payloadContent = 'medwiki, gr, epub';
      break;
    case 'opinions':
      payloadContent = 'survey';
      break;
    case 'watch':
      payloadContent = 'archive_video';
      break;

    case 'all cme':
    case 'upcoming':
    case 'reserved':
      payloadContent = 'session';
      break;
    case 'all':
      payloadContent = 'medwiki,gr,epub,archive_video,discussreffer';
      break;
    default:
      // payloadContent = ['comp', 'survey', 'gr', 'epub', 'video_archive'];
      break;
  }
  return useInfiniteQuery(
    [MOST_READ_FEED_DATA, speciality, content_type],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `dashboard/get_mostread_content?from=${pageParam}&to=5&speciality_id=${speciality}&content_type=${payloadContent}`
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          console.log('allPages.length', allPages.length);
          return allPages.length * 5;
        } else {
          return undefined;
        }
      },
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!speciality || speciality != 0 || payloadContent?.length <= 0
    }
  );
};

const saveLike = (data) => {
  return axiosInstance.post('knwlg/save_like', data);
};

export const useAddLike = (speciality, type) => {
  console.log('oldQueryData::1', speciality, type);
  const queryClient = useQueryClient();
  return useMutation(saveLike, {
    onMutate: async (obj) => {
      const queryKeysArr = [SPECIALITY_FEED_DATA, speciality, type];
      const previousData = queryClient.getQueryData(queryKeysArr);
      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        let temp_data = oldQueryData;
        console.log('oldQueryData\n', temp_data);

        const temp = temp_data.pages.map((page) =>
          page.data?.map((item) =>
            item.type_id === speciality
              ? { ...item, comments: [...post.comments, data.newComment] }
              : data
          )
        );
        return temp_data;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_CHANNEL_DETAILS, type_id]);
    }
  });
};
