import React, { memo } from 'react'
import { Skeleton } from '../../../../../skeleton/Skeleton'

function ObservershipRequestCardSkeleton() {
  return (
    <>
    {/* <h4 className='fs-3 text-black fw-semibold mt-2'>
         <Skeleton variant={'text'} height={35} width={250} />
    </h4> */}
    
        
            <div className='w-100 d-flex gap-md-4 gap-3 rounded-3 bg-white p-3 p-sm-4 cursorPointer'>
            <div className='transition flex-shrink-0 align-items-start position-relative'>
                <div className='rounded-circle overflow-hidden d-flex align-items-center justify-content-center  icon-size-48 icon-size-md-60 '>
                    <Skeleton variant={'rectangular'} className='object-fit-cover w-100 h-100'/>
                </div>
            </div>
            <div className='d-flex gap-2 justify-content-between flex-grow-1'>
                <div className='d-flex flex-column gap-1 flex-grow-1 align-items-start justify-content-center'>
                    <h4 className='fs-16 text-black fw-semibold'>
                        <Skeleton variant={'text'} height={25} width={150} />
                    </h4>
                    <div className='d-flex gap-3 fs-4 flex-wrap align-items-center'>
                    <Skeleton variant={'text'} height={25} width={90} />
                    </div>
                </div>
                <div className='d-flex flex-column flex-shrink-0 gap-md-3 align-items-end'>
                    <h5 className='fs-5 d-flex align-items-center gap-2 text-black fw-normal flex-grow-0'>
                    <Skeleton variant={'circular'} height={18} width={18} />
                    <Skeleton variant={'text'} height={20} width={110} /> 
                    </h5>
                    <div className=' d-flex  gap-3 align-items-center'>
                        <h6 className='fs-5 text-dark fw-normal fst-italic'>
                        <Skeleton variant={'text'} height={20} width={100} /> 
                        </h6>
                        
                        <Skeleton variant={'text'} height={54} width={55}/> 
                       
                    </div>
                </div>
            </div>
        </div>
        
    
    
    </>
  )
}

export default memo(ObservershipRequestCardSkeleton);