import React, { memo } from 'react';
import { CertificateIcon } from '../../icons';
import LazyImage from '../../CustomLibraries/LazyImage';

const SpecialityCard = ({ data = '', icon = null, onClick }) => {
  return (
    <div className="position-relative overflow-hidden" onClick={onClick}>
      <div className="position-relative overflow-hidden rounded-3 p-3 px-4 bg-white w-100 h-100">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center justify-content-center icon-size-32 icon-size-sm-36 rounded-circle p-2 bg-light mb-3">
            {/* <LazyImage
                        src={icon}
                        alt="speciality-icon"
                        height={15}
                      /> */}
            <img src={icon} alt="speciality-icon" height={15} />
          </div>
          <span className="flex-grow-1 mt-auto fs-4 text-black">{data}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(SpecialityCard);
