import { routeNames } from '../../router/constants';

const importStatements = [
  {
    path: routeNames.differentialDiagnosis.landing,
    statement: () => import('../../pages/askClirnet/DiagnosisHelp')
  },
  {
    path: routeNames.speciality.landing,
    statement: () => import('../../pages/speciality/SpecialityLanding')
  },
  {
    path: routeNames.medwiki.mostRead,
    statement: () => import('../../pages/mostReadContent/MostReadContent')
  },
  {
    path: routeNames.speciality.vault,
    statement: () => import('../../pages/vault/Vault')
  },
  {
    path: routeNames.helpAndSupport.landing,
    statement: () => import('../../pages/helpAndSupport/HelpAndSupport')
  },
  {
    path: routeNames.search.landing,
    statement: () => import('../../pages/Search/SearchV3')
  },
  {
    path: routeNames.search.tag,
    statement: () => import('../../pages/Search/TagSearch')
  },
  {
    path: routeNames.search.listing,
    statement: () => import('../../pages/Search/SearchList')
  },
  {
    path: routeNames.certificate.upload,
    statement: () => import('../../pages/certificates/UploadCertificate')
  },
  {
    path: routeNames.certificate.landing,
    statement: () => import('../../pages/certificates/CertificatesListing')
  },
  {
    path: routeNames.cpdReview.landing,
    statement: () => import('../../pages/userProfile/CpdReview')
  },
  {
    path: routeNames.sessions.upcoming,
    statement: () => import('../../pages/SpeakerProfile/UpcomingSessionsList')
  },
  {
    path: routeNames.sessions.previous,
    statement: () => import('../../pages/SpeakerProfile/PrviousSession')
  },
  {
    path: routeNames.sessions.queryListing,
    statement: () => import('../../pages/SpeakerProfile/QueryListing')
  },
  {
    path: routeNames.spq.question,
    statement: () => import('../../pages/spq/SPQquestion')
  },
  {
    path: routeNames.spq.review,
    statement: () => import('../../pages/spq/SPQreview')
  },
  {
    path: routeNames.spq.result,
    statement: () => import('../../pages/spq/SPQsubmitSuccessfully')
  },
  {
    path: routeNames.spq.attempt,
    statement: () => import('../../pages/spq/SPQcompleted')
  },
  {
    path: routeNames.spq.listing,
    statement: () => import('../../pages/spq/spq')
  },
  {
    path: routeNames.spq.listingPoll,
    statement: () => import('../../pages/spq/spq')
  },
  {
    path: routeNames.sessions.landing,
    statement: () => import('../../pages/session/SessionListing')
  },
  {
    path: routeNames.sessions.listing,
    statement: () => import('../../pages/session/AllSessionList')
  },
  {
    path: routeNames.epaper.landing,
    statement: () => import('../../pages/ebook/EbookListing')
  },
  {
    path: routeNames.medwiki.listing,
    statement: () => import('../../pages/medwiki/MedwikiListing')
  },
  {
    path: routeNames.medwiki.mostCommented,
    statement: () => import('../../pages/medwikiV2/MostCommentedMedwikiListing')
  },
  {
    path: routeNames.medwiki.popular,
    statement: () => import('../../pages/medwiki/MedwikiListing')
  },
  {
    path: routeNames.grandRound.landing,
    statement: () => import('../../pages/grandRounds/GrandRoundListing')
  },
  {
    path: routeNames.discussRefer.landing,
    statement: () => import('../../pages/discussRefer/DiscussRefer')
  },
  {
    path: routeNames.hospitalProfile.landing,
    statement: () => import('../../pages/discussRefer/hospitalProfile')
  },
  {
    path: routeNames.discussRefer.discussForm,
    statement: () => import('../../pages/discussRefer/DiscussForm')
  },
  {
    path: routeNames.discussRefer.referForm,
    statement: () => import('../../pages/discussRefer/DiscussForm')
  },
  {
    path: routeNames.speciality.activities,
    statement: () => import('../../pages/discussRefer/Activities')
  },
  {
    path: routeNames.discussRefer.referReinitiate,
    statement: () => import('../../pages/discussRefer/ReInitiateRefer')
  },
  {
    path: routeNames.discussRefer.referSubmit,
    statement: () => import('../../pages/discussRefer/ReferThankyou')
  },
  {
    path: routeNames.clinicalVideo.listing,
    statement: () => import('../../pages/clinicalVideo/ClinicalVideoList')
  },
  {
    path: routeNames.medwiki.landing,
    statement: () => import('../../pages/medwikiV2/MedwikiLandingV2')
  },
  {
    path: routeNames.clinicalVideo.landing,
    statement: () => import('../../pages/clinicalVideo/ClinicalVideoLanding')
  },
  {
    path: routeNames.channel.landing,
    statement: () => import('../../pages/channel/ChannelLandingV2')
  },
  {
    path: routeNames.channel.manage,
    statement: () => import('../../pages/channel/ChannelManage')
  },
  {
    path: routeNames.channel.suggestion,
    statement: () => import('../../pages/channel/ChannelSugesstion')
  },
  {
    path: routeNames.medwiki.topRated,
    statement: () => import('../../pages/medwikiV2/TopRatedMedwikiListing')
  },
  {
    path: routeNames.telemed.landing,
    statement: () => import('../../pages/telemedLite/TelemedLite')
  },
  {
    path: routeNames.spq.landing,
    statement: () => import('../../pages/spq/SPQlanding')
  },
  {
    path: routeNames.spq.points,
    statement: () => import('../../pages/spq/UserPoint')
  },
  {
    path: routeNames.election.landing,
    statement: () => import('../../pages/election/ElectionLanding')
  },
  {
    path: routeNames.election.candidateSelection,
    statement: () => import('../../pages/election/CandidateSelection')
  },
  {
    path: routeNames.election.voting,
    statement: () => import('../../pages/election/VotingSummary')
  },
  {
    path: routeNames.verification.mobile,
    statement: () => import('../../pages/election/MobileVerification')
  },
  {
    path: routeNames.doctube.profileVideo,
    statement: () => import('../../pages/userProfile/ProfileVideos')
  },
  {
    path: routeNames.course.landing,
    statement: () => import('../../pages/grTraining/TrainingLanding')
  },

  {
    path: routeNames.course.summary,
    statement: () => import('../../pages/grTraining/TrainingSummary')
  },
  {
    path: routeNames.notification.landing,
    statement: () => import('../../pages/notification/Notification')
  },
  {
    path: routeNames.doctube.landing,
    statement: () => import('../../pages/doctubeLanding/DoctubeLanding')
  },
  {
    path: routeNames.doctube.listing,
    statement: () =>
      import('../../pages/doctube/doctubeProfileManagement/components/listing/DoctubeListingVideos')
  },
  {
    path: routeNames.doctube.profile,
    statement: () => import('../../pages/doctube/doctubeProfileManagement/ProfileSteps')
  },
  {
    path: routeNames.doctube.dashboard,
    statement: () => import('../../pages/doctube/doctubeProfileManagement/DoctubeDashboard')
  },
  {
    path: routeNames.doctube.analytics,
    statement: () => import('../../pages/doctube/doctubeProfileManagement/DoctubeDashboard')
  },
  {
    path: routeNames.doctube.upload,
    statement: () => import('../../pages/doctube/doctubeProfileManagement/DoctubeVideoUpload')
  },
  {
    path: routeNames.doctube.playlistManage,
    statement: () => import('../../pages/doctube/doctubeProfileManagement/DoctubeManagePlaylist')
  },
  {
    path: routeNames.course.listing,
    statement: () => import('../../pages/grTraining/TrainingListing')
  },
  {
    path: routeNames.dashboard.landing,
    statement: () => import('../../pages/uiv3/dashboard/Dashboard'),
    oldStatement: () => import('../../pages/dashboard/Dashboard')
  },
  {
    path: routeNames.userProfile.landing,
    statement: () => import('../../pages/uiv3/profile/Profile'),
    oldStatement: () => import('../../pages/userProfile/UserProfileBkp')
  },
  {
    path: routeNames.speciality.listing,
    statement: () => import('../../pages/uiv3/AllSpeciality')
  },
  {
    path: routeNames.course.content,
    statement: () => import('../../pages/grTraining/TrainingContent')
  },
  {
    path: routeNames.sessions.speaker,
    statement: () => import('../../pages/SpeakerProfile/SpeakerProfile')
  },
  {
    path: routeNames.certificate.detail,
    statement: () => import('../../pages/certificates/CertificateDetails')
  },
  {
    path: routeNames.spq.detail,
    statement: () => import('../../pages/spq/SPQdetails')
  },
  {
    path: routeNames.spq.poll,
    statement: () => import('../../pages/spq/PollDetails')
  },
  {
    path: routeNames.sessions.detail,
    statement: () => import('../../pages/sessionOutlet/SessionDetailsOutlet')
  },
  {
    path: routeNames.clinicalVideo.detail,
    statement: () => import('../../pages/uiv3/clinicalVideo/ClinicalVideoDetails'),
    oldStatement: () => import('../../pages/clinicalVideo/ClinicalDetails')
  },
  {
    path: routeNames.sessions.live,
    statement: () => import('../../pages/sessionOutlet/LiveSessionOutlet')
  },
  {
    path: routeNames.sessions.photoBooth,
    statement: () => import('../../pages/multidaySession/PhotoBooth')
  },
  {
    path: routeNames.discussRefer.caseDetail,
    statement: () => import('../../pages/discussRefer/CaseDetails')
  },
  {
    path: routeNames.grandRound.detail,
    statement: () => import('../../pages/grandRounds/GrandRoundDetails')
  },
  {
    path: routeNames.doctube.playlist,
    statement: () => import('../../pages/doctube/doctubeProfileManagement/DoctubePlaylistDetails')
  },
  {
    path: routeNames.course.cover,
    statement: () => import('../../pages/grTraining/TrainingCover')
  },
  {
    path: routeNames.observership.landing,
    statement: () => import('../../pages/uiv3/observership/ObservershipLanding')
  },
  {
    path: routeNames.observership.registration,
    statement: () => import('../../pages/uiv3/observership/ObservershipRegistration')
  },
  {
    path: routeNames.observership.profile,
    statement: () => import('../../pages/uiv3/observership/ObservershipProfile')
  },
  {
    path: routeNames.observership.postOpportunity,
    statement: () => import('../../pages/uiv3/observership/PostOpportunity')
  },
  {
    path: routeNames.observership.observershipDetails,
    statement: () => import('../../pages/uiv3/observership/ObservershipDetails')
  },
  {
    path: routeNames.observership.observershipDetailsPreview,
    statement: () => import('../../pages/uiv3/observership/ObservershipDetails')
  },
  {
    path: routeNames.observership.observershpChat,
    statement: () => import('../../pages/uiv3/observership/ObservershpChat')
  },

  {
    path: routeNames.medwiki.detail,
    statement: () => import('../../pages/uiv3/medwiki/MedwikiDetails'),
    oldStatement: () => import('../../pages/medwikiV2/MedwikiDetailsV2')
  },
  {
    path: routeNames.channel.detail,
    statement: () => import('../../pages/uiv3/channel/ChannelProfile')
  },
  {
    path: routeNames.epaper.detail,
    statement: () => import('../../pages/uiv3/epaper/EpaperDetails'),
    oldStatement: () => import('../../pages/ebook/EbookDetails')
  },
  {
    path: routeNames.observership.applyForObserverShip,
    statement: () => import('../../pages/uiv3/observership/ApplyToOpportunity')
  },
  {
    path: routeNames.observership.applicantsListing,
    statement: () => import('../../pages/uiv3/observership/ApplicantsListing')
  },
  {
    path: routeNames.observership.applicationDetails,
    statement: () => import('../../pages/uiv3/observership/ApplicationDetails')
  },
  {
    path: routeNames.ddi.landing,
    statement: () => import('../../pages/ddi/DrugToDrugInteraction')
  },
  {
    path: routeNames.aida.landing,
    statement: () => import('../../pages/uiv3/aida/AiDALanding'),
    oldStatement: () => import('../../pages/uiv3/aida/AiDALanding')
  },
  {
    path: 'SelectSpeciality',
    statement: () => import('../../pages/uiv3/dashboard/SpecialitySelector'),
    oldStatement: () => import('../../pages/uiv3/dashboard/SpecialitySelector')
  },
  {
    path: routeNames?.share?.gr,
    statement: () => import('../../pages/share/GrShare'),
    oldStatement: () => import('../../pages/share/GrShare')
  },
  {
    path: routeNames?.share?.training,
    statement: () => import('../../pages/share/TrainingShare'),
    oldStatement: () => import('../../pages/share/TrainingShare')
  },
  {
    path: routeNames?.share?.ddx,
    statement: () => import('../../pages/share/DiagnosisShare'),
    oldStatement: () => import('../../pages/share/DiagnosisShare')
  },
  {
    path: routeNames?.share?.ddi,
    statement: () => import('../../pages/share/DDIShare'),
    oldStatement: () => import('../../pages/share/DDIShare')
  },
  {
    path: routeNames?.share?.openddx,
    statement: () => import('../../pages/askClirnet/DiagnosisHelp'),
    oldStatement: () => import('../../pages/askClirnet/DiagnosisHelp')
  },
  {
    path: routeNames?.share?.medwiki,
    statement: () => import('../../pages/share/MedwikiShare'),
    oldStatement: () => import('../../pages/share/MedwikiShare')
  },
  {
    path: '/share/feed/:id',
    statement: () => import('../../pages/share/MedwikiShare'),
    oldStatement: () => import('../../pages/share/MedwikiShare')
  },
  {
    path: routeNames?.share?.poll,
    statement: () => import('../../pages/share/PollShare'),
    oldStatement: () => import('../../pages/share/PollShare')
  },
  {
    path: routeNames?.share?.quiz,
    oldStatement: () => import('../../pages/share/QuizShare'),
    statement: () => import('../../pages/share/QuizShare')
  },
  {
    path: routeNames?.share?.session,
    statement: () => import('../../pages/share/SessionShare'),
    oldStatement: () => import('../../pages/share/SessionShare')
  },
  {
    path: routeNames?.share?.doctorVoice,
    statement: () => import('../../pages/share/PollShare'),
    oldStatement: () => import('../../pages/share/PollShare')
  },
  {
    path: routeNames?.share?.channel,
    statement: () => import('../../pages/share/channelShare'),
    oldStatement: () => import('../../pages/share/channelShare')
  },
  {
    path: routeNames?.share?.epub,
    statement: () => import('../../pages/share/ebookShare'),
    oldStatement: () => import('../../pages/share/ebookShare')
  },
  {
    path: routeNames?.share?.election,
    statement: () => import('../../pages/share/ElectionShare'),
    oldStatement: () => import('../../pages/share/ElectionShare')
  },
  {
    path: routeNames?.share?.dnr,
    statement: () => import('../../pages/share/DnrShare'),
    oldStatement: () => import('../../pages/share/DnrShare')
  },
  {
    path: routeNames?.share?.doctube,
    statement: () => import('../../pages/share/DoctubeShare'),
    oldStatement: () => import('../../pages/share/DoctubeShare')
  },
  {
    path: routeNames?.share?.doctube_fst,
    statement: () => import('../../pages/share/DoctubeShare'),
    oldStatement: () => import('../../pages/share/DoctubeShare')
  },
  {
    path: routeNames?.share?.profile,
    statement: () => import('../../pages/share/profileShare'),
    oldStatement: () => import('../../pages/share/profileShare')
  },
  {
    path: routeNames?.share?.clinicalVideo,
    statement: () => import('../../pages/share/ClinicalVideosShare'),
    oldStatement: () => import('../../pages/share/ClinicalVideosShare')
  },
  {
    path: routeNames?.share?.observership,
    statement: () => import('../../pages/share/ObservershipShare'),
    oldStatement: () => import('../../pages/share/ObservershipShare')
  },
  {
    path: routeNames?.share?.mentorship,
    statement: () => import('../../pages/share/MentorShare'),
    oldStatement: () => import('../../pages/share/MentorShare')
  },
  {
    path: routeNames?.share?.claimMentorship,
    statement: () => import('../../pages/share/ClaimMentorship'),
    oldStatement: () => import('../../pages/share/ClaimMentorship')
  }
];
export default importStatements;
