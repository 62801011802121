export const cardWrapperDefaulOptions = {
  bgColor: 'white',
  display: 'flex',
  rounded: '4',
  flexDirection: 'row',
  gap: {
    md: '4',
    default: '4'
  }
};
export const featuredTagDefaultOptions = {
  bgColor: 'primary',
  color: 'white',
  iconSize: '50',
  fontSize: '2',
  iconName: 'danger'
};

export const premiumTagDefaultOptions = {
  iconSize: {
    lg: '36',
    default: '32'
  },
  fontSize: {
    lg: '2',
    default: '1'
  },
  color: 'black'
};

export const cardContainerDefaultOptions = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    md: '2',
    default: '1'
  },
  padding: {
    default: '3',
    md: '4'
  },
  paddingTop: {
    default: '2',
    md: '3'
  }
};

export const cardHeaderDefaultOptions = {
  display: 'flex',
  flexDirection: 'row',
  gap: {
    default: '3'
  }
};

export const headerTagDefaultOptions = {
  iconColor: 'primary',
  color: 'black',
  fontSize: '5',
  iconSize: '3',
  gap: {
    default: '2'
  }
};

export const headerRightDefaultOptions = {
  flex: {
    gap: {
      md: '3',
      default: '2'
    }
  }
};

export const cardBodyDefaultOptions = {
  flexDirection: 'column',
  rowGap: {
    md: '3',
    default: '3'
  },
  columnGap: {
    md: '4',
    default: '4'
  }
};

export const cardImageDefaultOptions = {
  rounded: '3',
  bgColor: 'soft-dark'
};

export const bodyContentDefaultOptions = {
  gap: {
    md: '1',
    default: '1'
  }
};

export const contentTitleDefaultOptions = {
  fontSize: '16',
  color: 'black',
  lineClamp: '2',
  fontWeight: 'semibold'
};

export const contentDescriptionDefaultOptions = {
  fontSize: '4',
  color: 'dark',
  lineClamp: '2'
};

export const cardFooterDefaultOptions = {
  flexDirection: 'row',
  gap: {
    md: '3',
    default: '3'
  }
};

export const footerLeftActionBarDefaultOptions = {
  gap: {
    md: '4',
    default: '4'
  }
};

export const footerLeftIconDefaultOptions = {
  iconSize: '16',
  iconColor: 'dark',
  color: 'dark',
  fontSize: '4'
};

export const sponserDefaultOptions = {
  fontSize: '16',
  bgColor: 'black'
};

export const buttonContainerDefault = {
  gap: {
    md: '3',
    default: '3'
  }
};

export const buttonDefaultOptions = {
  gap: {
    md: '3',
    default: '3'
  },
  variant: 'primary'
};
// export const sponsorLogoDefault = {

// };
