import dayjs from 'dayjs';
import {
  CARD_CLICK,
  EXPLORE_MORE_CLICK,
  GET_RECORDING_CLICK,
  BANNER_LOAD,
  LIKE_CLICK,
  VAULT_CLICK,
  COMMENT_CLICK,
  COMMENT_LIKE_CLICK,
  COMMENT_REPLY_CLICK,
  COMMENT_SUBMIT,
  COMMENT_REPLY_SUBMIT
} from './constants';

// const dayjs(new Date().getTime()).format("HH:mm:ss") = dayjs(new Date().getTime()).format("HH:mm:ss");

export const onCardClick = (id, cardName) => {
  return {
    type: CARD_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const onBannerLoad = (id, cardName = 'banner') => {
  return {
    type: BANNER_LOAD,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const trackEvent = (eve = NOT_FOUND) => {
  return {
    type: EXPLORE_MORE_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    event: eve
  };
};

export const onRecordingClick = (id, cardName) => {
  return {
    type: GET_RECORDING_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const onLikeClick = (id, cardName) => {
  return {
    type: LIKE_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const onVaultClick = (id, cardName) => {
  return {
    type: VAULT_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const onCommentSubmit = (id, cardName) => {
  return {
    type: COMMENT_SUBMIT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const onCommentLikeClick = (id, cardName) => {
  return {
    type: COMMENT_LIKE_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};

export const onCommentReplySubmit = (id, cardName) => {
  return {
    type: COMMENT_REPLY_SUBMIT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName
  };
};
