import React, { memo } from 'react';
import Card from '../baseUiV3/components/Card';
import { Skeleton } from '../../skeleton/Skeleton';

const SponsorCard = ({ bannerDiv, title, buttonTxt, btnClick = () => {} }) => {
  return (
    <Card className={`clr_v3_card__sponsor  clr_v3_card__sponsor--color`}>
      <Card.Body>
        <Card.Header>
          <div className="d-flex flex-column bd-highlight mb-3"></div>
          <span className="clr_v3_card__icon d-inline-flex align-items-center justify-content-center me-auto">
            <i className="flaticon-ad-sponsor fs-3 me-2 text-primary"></i>
            <span>Sponsor</span>
          </span>
        </Card.Header>
        <div className="d-flex">
          <div className="flex-grow-1 d-flex justify-content-center flex-column">
            {/* {selectedCampaign && selectedCampaign?.CTA_text && ( */}
            <Card.Title>{title ? title : <span className="opacity-0">a</span>}</Card.Title>
            {/* )} */}
          </div>
        </div>
        <Card.Image className="mt-4">{bannerDiv && bannerDiv}</Card.Image>
        {/* {buttonTxt && ( */}
        <div className="mt-4">
          <button
            onClick={btnClick}
            className="btn btn-primary text-truncate fw-semibold rounded-pill border-105 w-100"
          >
            {buttonTxt ? buttonTxt : 'Click Now'}
          </button>
        </div>
        {/* )} */}
      </Card.Body>
    </Card>
  );
};

export default memo(SponsorCard);
