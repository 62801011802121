export default function getInitials(fullName, length = null) {
  // Split the full name into an array of words
  const words = fullName.split(' ');

  // Extract the first letter of each word and join them to form initials
  const initials = words.map((word) => word.charAt(0)).join('');

  if (length) {
    return initials.toUpperCase().slice(0, length);
  } else {
    return initials.toUpperCase(); // Convert to uppercase for consistency
  }
}
