import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import packageJson from '../../../../../package.json';
import LazyImage from '../../../CustomLibraries/LazyImage';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation]);
const ProfileDrawer = ({ show, handleClose }) => {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement={'end'} name={'end'}>
        <Offcanvas.Body className="d-flex flex-column">
          <svg
            class="position-absolute top-0 end-0 w-100 h-100 z-n1 pulseWave"
            preserveAspectRatio="xMidYMid slice"
            viewBox="10 10 80 80"
          >
            <path
              class="pulseWave__big"
              d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"
            />
            <path
              class="pulseWave__small"
              d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z"
            />
          </svg>
          <Button
            variant="light"
            className=" p-0 icon-size-32 rounded-circle d-flex justify-content-center align-items-center ms-auto"
            onClick={handleClose}
          >
            <i className="flaticon-close fs-5"></i>
          </Button>
          <div className=" d-flex flex-column gap-20">
            <div className="d-flex flex-column gap-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="rounded-circle p-2 border border-2 border-primary position-relative">
                  <div className="overflow-hidden  icon-size-80 rounded-circle position-relative">
                    {/* {userDetails && userDetails?.profile_image && ( */}
                    <LazyImage
                      // src={userDetails?.profile_image}
                      src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                      width=""
                      height=""
                      alt="Dr. User Image"
                      // fallback_image={DEMO_USER_PICTURE}
                      className="object-fit-cover w-100 h-100 float-start"
                    />
                    {/* )} */}
                  </div>
                  {/* <div className="position-absolute end-0 bottom-0 btn btn-primary rounded-circle d-flex align-items-center justify-content-center z-2 p-0 icon-size-32 overflow-hidden cursorPointer">
                  <input
                    type="file"
                    className="position-absolute start-0 top-0 w-100 h-100 opacity-0 z-n1"
                    onChange={(e) => uploadProfileImage(e.target.files[0])}
                    ref={inputRef}
                  />
                  <i
                    className="flaticon-camera fs-3 text-white"
                    onClick={() => inputRef.current.click()}
                  ></i>
                </div> */}
                </div>
                <Button
                  size="sm"
                  className={`clr_v3_premium__tag shineEffect text-black fs-5 d-inline-flex gap-2 d-inline-flex align-items-center justify-content-center fw-semibold rounded-3 border-0`}
                >
                  <i className={` flaticon-premium fs-16`}></i>
                  Subscribe Now
                </Button>
              </div>
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <div className="flex-grow-1">
                  {/* {(userDetails?.first_name ||
                  userDetails?.last_name ||
                  userDetails?.middle_name) && ( */}
                  <h4
                    className="fs-2 fw-semibold mb-1 text-black cursorPointer"
                    // onClick={onEditProfileClick}
                  >
                    {/* {`${userDetails?.first_name} ${userDetails?.middle_name} ${userDetails?.last_name}`} */}
                    Dr. Static Name
                  </h4>
                  {/* )}
                {userDetails?.speciality && ( */}
                  <p className="fs-4">
                    {/* {parseSpecialities(userDetails?.speciality)} */}
                    Static Speciality
                  </p>
                  {/* )} */}
                </div>
                <span className="icon-size-32 btn btn-light p-0 d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <i className="flaticon-next fs-4"></i>
                </span>
              </div>
            </div>
            <ul className="list-unstyled">
              <li>
                <a
                  href="javascript:void(0)"
                  className="rounded-3 gap-3 d-flex align-items-center py-3 text-black fw-medium"
                >
                  <span className="icon-size-24 rounded-3 d-inline-flex align-items-center justify-content-center">
                    <i className="flaticon-bookmark fs-1"></i>
                  </span>
                  <span>Saved</span>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="rounded-3 gap-3 d-flex align-items-center py-3 text-black fw-medium"
                >
                  <span className="icon-size-24 rounded-3 d-inline-flex align-items-center justify-content-center">
                    <i className="flaticon-customer-service-headset fs-1"></i>
                  </span>
                  <span>Help & Support</span>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="rounded-3 gap-3 d-flex align-items-center py-3 text-black fw-medium"
                >
                  <span className="icon-size-24 rounded-3 d-inline-flex align-items-center justify-content-center">
                    <i className="flaticon-logout fs-1"></i>
                  </span>
                  <span>Log out</span>
                </a>
              </li>
            </ul>
            <div className="">
              <h5 className="fs-4 fw-semibold text-black mb-3">Change Color Mode</h5>

              <Swiper
                className="clr_v3_swiper"
                loop={false}
                freeMode={true}
                spaceBetween={4}
                slidesPerView={'auto'}
                navigation={true}
              >
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'speciality'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                      checked
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'General Surgery'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Hepatology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Pathology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Allergy & Immunology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Obstetrics & Gynaecology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Allergy & Immunology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Allergy & Immunology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Allergy & Immunology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="w-auto">
                  <div
                    class="form-check position-relative clr_v3_profileDrawer__radio"
                    data-bs-theme={'Allergy & Immunology'}
                  >
                    <input
                      className="form-check-input position-absolute top-0 start-0 w-100 h-100 z-1 opacity-0 m-0"
                      type="radio"
                      value=""
                      name="chanege color theme"
                    />
                    <label
                      class="form-check-label icon-size-32 border border-2 border-white bg-primary d-inline-flex align-items-center justify-content-center rounded-2 overflow-hidden"
                      for="flexCheckDefault"
                    >
                      <i className="flaticon-check fs-2 text-white"></i>
                    </label>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="mt-auto">
            <div className=" d-flex flex-column gap-20 mt-4">
              <ul className="d-flex flex-column gap-2 align-items-start list-unstyled m-0">
                <li>
                  <a
                    className="text-black"
                    target="_blank"
                    //   href={
                    //     userRegionState === 'GT' || userRegionState === 'GL'
                    //       ? process.env.REACT_APP_TERMS_CONDITIONS_LINK_GT
                    //       : process.env.REACT_APP_TERMS_CONDITIONS_LINK
                    //   }
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    className="text-black"
                    target="_blank"
                    //   href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
              <div className="d-flex flex-row gap-2">
                <a
                  // href={process.env.REACT_APP_FACEBOOK_LINK}
                  target="_blank"
                  className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
                >
                  <i className="flaticon-facebook-app-symbol fs-4"></i>
                </a>
                <a
                  // href={process.env.REACT_APP_TWITTER_LINK}
                  target="_blank"
                  className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
                >
                  <i className="flaticon-twitter fs-4"></i>
                </a>
                <a
                  // href={process.env.REACT_APP_INSTAGRAM_LINK}
                  target="_blank"
                  className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
                >
                  <i className="flaticon-instagram fs-4"></i>
                </a>
                <a
                  // href={process.env.REACT_APP_LINKEDIN_LINK}
                  target="_blank"
                  className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
                >
                  <i className="flaticon-linkedin fs-4"></i>
                </a>
                <a
                  // href={process.env.REACT_APP_YOUTUBE_LINK}
                  target="_blank"
                  className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
                >
                  <i className="flaticon-youtube fs-4"></i>
                </a>
              </div>
            </div>
            <h4 className="fs-16 text-black fw-bold  mt-4">CLIRNET v-{packageJson.version}</h4>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ProfileDrawer;
