export const USER_DETAILS = 'USER_DETAILS';
export const PROFILE_COMPLETION = 'PROFILE_COMPLETION';
export const USER_PROFILE_ALL = 'USER_PROFILE_ALL';
export const ADD_PROFILE_SECTION = 'ADD_PROFILE_SECTION';
export const EDIT_PROFILE_SECTION = 'EDIT_PROFILE_SECTION';
export const EDIT_DESCRIPTION = 'EDIT_DESCRIPTION';
export const EDIT_NAME = 'EDIT_NAME';
export const ALL_SPECIALITIES = 'ALL_SPECIALITIES';
export const REQUESTED_RECORDINGS = 'REQUESTED_RECORDINGS';
export const PROFILE_VIDEOS = 'PROFILE_VIDEOS';
export const CPD_MATRICS = 'CPD_MATRICS';
export const CPD_DATA = 'CPD_DATA';
export const CPD_REELS = 'CPD_REELS';
