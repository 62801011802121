import React, { memo, useEffect, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import getInitials from '../../../../common/getInitials';
import useRedirect from '../../../../components/customHooks/useRedirect';
import dayjs from 'dayjs';
import { routeNames } from '../../../../router/constants';
import { parseJson } from '../../../../common/common';

function ObservershipRequestCard({
  profile_image,
  application_details,
  added_on,
  status,
  id,
  hideDetailsBtn = false,
  disableClick = false
}) {
  const { redirectTo } = useRedirect();
  const details = application_details ? JSON.parse(application_details) : {};
  function cardClick() {
    if (disableClick) {
      return false;
    } else {
      redirectTo(routeNames.observership.applicationDetails, id);
    }
  }

  const {
    name,
    speciality,
    state_id = { state_name: '' },
    city_id = { region_name: '' },
    county_id = { country_name: '' },
    applied_on
  } = details;
  const { country_name, iso } = JSON.parse(county_id);
  const { state_name } = JSON.parse(state_id);
  const { region_name } = JSON.parse(city_id);

  const { master_specialities_id, specialities_name } = parseJson(speciality);
  const [requestCard, setRequestCard] = useState(0);
  const requestCardHeight = useRef(0);
  useEffect(() => {
    if (requestCardHeight?.current) {
      setRequestCard(requestCardHeight?.current?.offsetHeight);
    }
  }, [requestCardHeight?.current]);

  return (
    <div
      className="w-100 d-flex gap-md-4 gap-3 rounded-3 bg-white p-3 p-sm-4 cursorPointer position-relative overflow-hidden"
      ref={requestCardHeight}
      onClick={cardClick}
    >
      <div className="transition flex-shrink-0 align-items-start position-relative">
        <div className="rounded-circle overflow-hidden d-flex align-items-center justify-content-center  icon-size-48 icon-size-md-60   bg-primary ">
          {profile_image ? (
            <img
              src={profile_image}
              width="36"
              height="36"
              // alt={doctor.first_name}
              className="object-fit-cover w-100 h-100"
            />
          ) : (
            <span className="text-white fs-1 fw-semibold">{getInitials(name, 1)}</span>
          )}
        </div>
      </div>
      <div className="d-flex flex-column gap-1 flex-grow-1">
        {status == 3 ? (
          <Badge className="bg-soft-success fw-normal fs-5 me-auto">Accepted</Badge>
        ) : (
          <></>
        )}
        {status == 1 ? (
          <Badge className="bg-soft-danger fw-normal fs-5 me-auto">Rejected</Badge>
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-between">
          <h4 className="fs-16 text-black fw-semibold">Dr. {name}</h4>
          {/* {applied_on ? ( */}

          {!isMobile && applied_on && (
            <h6
              className={`${
                isMobile ? 'fs-6' : 'fs-5'
              } text-dark fw-normal fst-italic flex-shrink-0`}
            >
              Applied on {dayjs(applied_on).format('D MMM, YYYY')}
            </h6>
          )}

          {/* ) : (
            <></>
          )} */}
        </div>

        {/* <span>Male</span> */}
        <div className="d-flex gap-3 flex-wrap">
          {specialities_name && (
            <a
              href="javascript:void(0)"
              className="hover-opacity"
              onClick={() =>
                redirectTo(routeNames.speciality.landing, master_specialities_id, specialities_name)
              }
            >
              {specialities_name}
            </a>
          )}
        </div>
        <h5 className="fs-5 d-flex align-items-center gap-2 mt-2 text-black fw-normal flex-grow-0">
          <i className="flaticon-pin text-primary"></i>{' '}
          <span className="line-clamp-1">
            {state_name ? `${state_name}, ` : null}
            {iso ? `${iso}` : country_name ? `${country_name}` : null}
          </span>
        </h5>
        {isMobile && applied_on && (
          <h6
            className={`${isMobile ? 'fs-6' : 'fs-5'} text-dark fw-normal fst-italic flex-shrink-0`}
          >
            Applied on {dayjs(applied_on).format('D MMM, YYYY')}
          </h6>
        )}
      </div>

      {/* <span
        className="position-absolute bg-success text-white d-flex align-items-center justify-content-center"
        style={{
          width: `${requestCard}px`,
          top: `${-(requestCard + 20) / 2}px`,
          right: `${-(requestCard + 20) / 2}px`,
          height: '20px',
          rotate: '-90deg'
        }}
      >
        Accepted
      </span> */}

      {/* <div className="d-flex flex-column flex-shrink-0 gap-md-3 align-items-end">
          {!isMobile && (
            <>
              <h5 className="fs-5 d-flex align-items-center gap-2 text-black fw-normal flex-grow-0">
                <i className="flaticon-pin text-primary"></i>{' '}
                <span className="line-clamp-1">
                  {' '}
                  {state_name ? `${state_name} ,` : null}
                  {region_name ? `${region_name} ,` : null}
                  {country_name ? `${country_name} ,` : null}
                </span>
              </h5>
              <div className=" d-flex  gap-3 align-items-center">
                {added_on ? (
                  <h6 className="fs-5 text-dark fw-normal fst-italic">
                    Applied on {dayjs(added_on).format('D MMM, YYYY')}
                  </h6>
                ) : (
                  <></>
                )}
                {hideDetailsBtn ? <></> : <Button size="sm">View</Button>}
              </div>
            </>
          )}

          {status == 3 ? <span>Accepted</span> : <></>}
          {status == 1 ? <span>Rejected</span> : <></>}
        </div> */}
    </div>
  );
}

export default memo(ObservershipRequestCard);
