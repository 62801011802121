import { Button, Dropdown, Navbar, Offcanvas } from 'react-bootstrap';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import 'swiper/css';
import { useGetTrendingTopic } from '../../../../Queries/QueryHooks/dashboard.hook';
import useRedirect from '../../../customHooks/useRedirect';
import { ClirnetIconLogo } from '../../../icons';
import { updateUserViewSpeciality } from '../../../../Store/actions/dashboard.action';
import {
  deleteCaches,
  getLocalStorage,
  isIosApp,
  isMobileApp,
  reactHtmlParser,
  setLocalStorage
} from '../../../../common/common';
import LazyImage from '../../../CustomLibraries/LazyImage';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from '../../../../Store/actions/settings.action';
import DeleteConfirmation from '../../../modals/DeleteConfirmation';
import { useLocation } from 'react-router-dom';
import JoyRide from '../../../reactJoyRide/JoyRide';
import { changeExploreKey } from '../../../../Store/actions';
import { routeNames } from '../../../../router/constants';
import LoaderLine from '../../../LoaderLine';
import LeftSideContent from '../../../../pages/uiv3/dashboard/components/LeftSideContent';
import ProfileDrawer from './ProfileDrawer';

const HeaderSpecialityMobile = ({ getElementHeight, selectedNavItem }) => {
  const { redirectTo } = useRedirect();
  const { pathname } = useLocation();
  const current_menu = useSelector((state) => state.common.current_menu);

  const prevScrollPos = useRef(window.pageYOffset);
  //usestate for deletion confirmation
  const [{ deletionType, deletionModalShow }, setDeletionConfirmation] = useState({
    deletionType: '',
    deletionModalShow: false
  });
  const headerSecRef = useRef(0);
  const getHeight = useRef(0);
  const [activeSlide, setActiveSlide] = useState('All');
  const [headerTransform, setHeaderTransform] = useState(0);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.common.user_details);
  const user_details = useSelector((state) => state.common.user_details);
  const [specialityDataShowing, setSpecialityDataShowing] = useState();

  const [userSpeciality, setUserSpeciality] = useState();

  const showingSpecData = useSelector((state) => state.dashboard.user_speciality_viewing);

  const handleScroll = () => {
    // alert('Scroll  detected!' + headerSecRef.current);
    const currentScrollPos = window?.pageYOffset;
    const headerHeight = headerSecRef?.current?.clientHeight;
    if (prevScrollPos?.current > currentScrollPos) {
      // alert('Scroll up detected!', headerHeight);
      if (headerHeight <= 0) {
        setHeaderTransform(headerHeight);
      }
    } else {
      setHeaderTransform(0);
      // alert('Scroll down detected!');
    }
    prevScrollPos.current = currentScrollPos;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const selectedSpeciality = getLocalStorage('selectedSpeciality', null);

    if (selectedSpeciality) {
      console.log('here++', [selectedSpeciality]);

      setSpecialityDataShowing([selectedSpeciality]);
      setUserSpeciality(
        getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.speciality) : ''
      );
    } else {
      setSpecialityDataShowing(
        getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.speciality) : ''
      );

      setUserSpeciality(
        getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.speciality) : ''
      );
    }
  }, []);

  useEffect(() => {
    getElementHeight && getElementHeight(getHeight.current.offsetHeight);
  }, [getHeight.current.offsetHeight]); //activeSlide

  const navItemHandler = (item) => {
    setActiveSlide(item);
    selectedNavItem(item);
  };

  const { data: trendingTopicData, isLoading } = useGetTrendingTopic(
    showingSpecData && showingSpecData?.length > 0 ? showingSpecData[0]?.master_specialities_id : ''
  );

  const onTrendingTopicClick = (pageName = 'text', page_id, content, conType) => {
    switch (pageName) {
      case 'comp':
        redirectTo(routeNames.medwiki.detail, page_id, content, `type=${conType}`);
        break;
      case 'gr':
        redirectTo(routeNames.grandRound.detail, page_id, content);
        break;
      case 'session':
        redirectTo(routeNames.sessions.detail, page_id, content);
        break;
      case 'survey':
        redirectTo(routeNames.spq.detail, page_id, content);
        break;
      case 'epub':
        redirectTo(routeNames.epaper.detail, page_id, content);
        break;
      case 'archivevideo':
        redirectTo(routeNames.clinicalVideo.detail, page_id, content);
        break;
      case 'channel':
        redirectTo(routeNames.channel.detail, page_id, content);
        break;
      default:
        break;
    }
  };

  const redirectToDashboard = () => {
    dispatch(changeExploreKey(null));
    redirectTo(routeNames.dashboard.landing);
  };

  const onReturnToSpecClick = () => {
    dispatch(updateUserViewSpeciality(userSpeciality));
    setLocalStorage('selectedSpeciality', userSpeciality[0]);

    // document.body.setAttribute('data-bs-theme', userSpeciality?.specialities_name ?? '');
  };
  const onChangeSpecialityClick = () => {
    console.log('onChangeSpecialityClick');
    redirectTo('SelectSpeciality');
  };

  const onSearchClick = () => {
    redirectTo(routeNames.search.landing);
  };
  const onNotificationClick = () => {
    redirectTo(routeNames.notification.landing);
  };
  const logout = (type) => {
    if (type == 'logout') {
      window.location.href = '/';
      localStorage.clear();
      sessionStorage.clear();
      deleteCaches();
    }
  };

  useEffect(() => {
    if (showingSpecData && specialityDataShowing != showingSpecData) {
      setSpecialityDataShowing(showingSpecData);
    }
  }, [showingSpecData, specialityDataShowing]);

  const isInDashboard = useMemo(() => {
    return pathname.toLowerCase()?.includes('dashboard');
  }, [pathname]);

  const HandleAppReload = () => {
    window.location.reload();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Navbar
      className={`clr_v3_Header pt-0 ${
        // || pageName == 'speciality_profile'
        current_menu !== 'dashboard' ? 'clr_v3_Header--navOnly position-sticky top-0' : ''
      }  bg-white w-100 flex-column pb-4 clr_v3_Header--mobile d-lg-none d-flex`}
      style={{ transform: `translateY(-${headerTransform}px)` }}
      ref={getHeight}
    >
      <LoaderLine />
      <JoyRide />
      <div className="position-relative w-100" ref={headerSecRef}>
        <div className="clr_v3_Header__navbar position-relative px-sm-4 px-3">
          <div className="clr_v3_Header__background overflow-hidden position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end">
            <img
              src="https://img.freepik.com/premium-photo/medicine-doctor-hold-medical-cross-icon-healthcare-network-innovation-healthcare-technology_34200-496.jpg?w=996"
              alt="cover"
              className="object-fit-cover w-100 h-100 position-absolute"
            />
            <div className="overlay bg-primary" style={{ opacity: 0.9 }}></div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="375"
              height="14"
              viewBox="0 0 375 14"
              className="clr_v3_Header__svgShape z-2 position-relative"
            >
              <path
                id="Path_1495"
                data-name="Path 1495"
                d="M0,0S96.25,11.121,190,11.121,375,0,375,0V14H0Z"
                fill="#fff"
              />
            </svg>
          </div>
          {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100"></div> : <></>}
          {isInDashboard &&
            userSpeciality?.length > 0 &&
            specialityDataShowing?.length > 0 &&
            userSpeciality[0]?.master_specialities_id !=
              specialityDataShowing[0]?.master_specialities_id && (
              <div
                className="px-3 bg-black bg-opacity-50 mx-sm-n4 mx-n3 py-2 text-white position-relative z-2 d-flex gap-2 justify-content-center align-items-center clr_v3_Header__outside_Speciality"
                onClick={onReturnToSpecClick}
              >
                <h2 className="fs-5">Viewing content outside your Speciality.</h2>
                <a
                  href="javascript:void(0)"
                  className="text-white fw-semibold d-inline-flex gap-1 fs-5 align-items-center"
                >
                  Return to my Speciality<i className="flaticon-chevron"></i>
                </a>
              </div>
            )}
          <nav className="d-flex justify-content-between align-items-center py-3 position-relative z-3 text-white clr_v3_Header__nav">
            <div
              onClick={redirectToDashboard}
              className="clr_v3_Header__brand icon-size-sm-50 icon-size-36 me-auto bg-white d-flex align-items-center justify-content-center rounded-3"
            >
              <ClirnetIconLogo />
            </div>
            <div className="ms-auto clr_v3_Header__right">
              <ul className="d-flex p-0 align-items-center list-unstyled fs-30 mb-0 gap-3">
                {isMobileApp() && (
                  <li onClick={HandleAppReload}>
                    <a
                      href="javascript:void(0)"
                      className="bg-white ratio-1 rounded-circle p-2 text-black"
                      style={{ '--bs-bg-opacity': '.9' }}
                      onClick={HandleAppReload}
                    >
                      <i className="flaticon-undo fs-30"></i>
                    </a>
                  </li>
                )}

                <li onClick={onSearchClick}>
                  <a href="javascript:void(0)">
                    <i className="flaticon-loupe fs-30"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" onClick={onNotificationClick}>
                    <i className="flaticon-notification fs-30"></i>
                  </a>
                </li>

                {/* <div
                  className="overflow-hidden border border-white icon-size-32 icon-size-sm-36 bg-white bg-opacity-10 rounded-circle"
                  onClick={handleShow}
                >
                  <LazyImage
                    alt={'user image'}
                    src={userDetails?.profile_image}
                    width=""
                    height=""
                    className="object-fit-cover w-100 h-100 float-start"
                  />
                </div> */}
                {/* <ProfileDrawer show={show} handleClose={handleClose} /> */}

                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="rounded-circle p-33 border border-white border-105 bg-transparent dropdown-toggle clr_v3_profileDropDown"
                  >
                    <div className="overflow-hidden border border-white icon-size-32 icon-size-sm-36 bg-white bg-opacity-10 rounded-circle">
                      <LazyImage
                        alt={'user image'}
                        src={userDetails?.profile_image}
                        width=""
                        height=""
                        className="object-fit-cover w-100 h-100 float-start"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      className="p-3 rounded bg-light d-flex align-items-center jusstify-content-start gap-3"
                      onClick={() => redirectTo(routeNames.userProfile.landing)}
                    >
                      <i className="flaticon-user-1 fs-2"></i>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="p-3 rounded bg-light d-flex align-items-center jusstify-content-start gap-3"
                      onClick={() =>
                        setDeletionConfirmation({
                          deletionType: 'logout',
                          deletionModalShow: true
                        })
                      }
                    >
                      <i className="flaticon-logout fs-2"></i>
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
          {isInDashboard && specialityDataShowing && specialityDataShowing?.length > 0 && (
            <div className="mb-4 z-2 position-relative d-flex clr_v3_Header__speciality">
              <a
                onClick={onChangeSpecialityClick}
                href="javascript:void(0)"
                id="change-speciality-button"
                className="ms-auto fs-16 py-2 btn btn-outline-white px-3 rounded-3 d-inline-flex align-content-center jusstify-content-center gap-2 clr_v3_Header__speciality__btn"
              >
                <i className="flaticon-transfer-data d-inline-block align-middle fs-1"></i>
                <span className="d-inline-block text-truncate">
                  {specialityDataShowing[0]?.specialities_name}
                </span>
              </a>
            </div>
          )}
        </div>
        {!isLoading &&
          trendingTopicData &&
          typeof trendingTopicData == 'object' &&
          'content_id' in trendingTopicData && (
            <div className="clr_v3_Header__trendingContainer px-sm-4 px-3 position-relative z-2">
              <div className="clr_v3_Header__trending p-4 pb-0 bg-white rounded-4 d-flex align-items-start">
                <div className="flex-grow-1 text-start">
                  <h2 className="fs-3 text-black fw-semibold mb-3 lh-1">
                    <i className="flaticon-danger text-primary me-2 d-inline-block fs-2 align-text-top"></i>
                    Trending Now
                  </h2>
                  <p
                    className="text-black line-clamp-2"
                    onClick={() =>
                      onTrendingTopicClick(
                        trendingTopicData?.content_type,
                        trendingTopicData?.content_id,
                        trendingTopicData?.content,
                        trendingTopicData?.con_type || 'text'
                      )
                    }
                  >
                    {reactHtmlParser(trendingTopicData?.content)}
                  </p>
                </div>
                <div
                  className="clr_v3_Header__trending__arrow flex-shrink-0 icon-size-36 btn btn-primary p-0 d-flex align-items-center justify-content-center rounded-circle ms-3"
                  onClick={() =>
                    onTrendingTopicClick(
                      trendingTopicData?.content_type,
                      trendingTopicData?.content_id,
                      trendingTopicData?.content,
                      trendingTopicData?.con_type || 'text'
                    )
                  }
                >
                  <i className="flaticon-next fs-3"></i>
                </div>
              </div>
            </div>
          )}
      </div>

      {deletionType && (
        <DeleteConfirmation
          title={`Are you sure to log out ?`}
          type={deletionType}
          confirm={deletionModalShow}
          handleClose={() =>
            setDeletionConfirmation({ deletionType: '', deletionModalShow: false })
          }
          confirmDeletion={logout}
        />
      )}
      {/* <HeaderNavBar selectedNavItem={selectedNavItem} /> */}
    </Navbar>
  );
};

export default HeaderSpecialityMobile;
