import dayjs from 'dayjs';
//import { reactLocalStorage } from "reactjs-localstorage";
import { getUtmSource, getAgent, getLocalStorage, setLocalStorage } from '../common/common';
import Package from '../../package.json';
import { sendTrackingReport, sendTrackingReportOpen } from './utils/utils';
const apiUrl = process.env.REACT_APP_API_URL;

let appVersion = Package.version;

export default class Tracker {
  constructor(options) {
    this.trackingJson = {
      page: options.pageName,
      page_id: options.pageId ? options.pageId : 0,
      referrer: options.referrer ? options.referrer : 0,
      activity_events: [],
      start_time: '',
      end_time: '',
      utm: '',
      platform: '',
      version: appVersion,
      token: ''
    };

    this.currentTime = dayjs(new Date().getTime()).format('HH:mm:ss');
    this.trackingHistory = [];
    this.trackEvent = this.trackEvent.bind(this);
    // console.assert("tracker called");
  }
  getCurrentTime = () => dayjs(new Date().getTime()).format('HH:mm:ss');
  on(eventType, data) {
    if (typeof data !== 'object') {
      throw new Error(`Expected onClick listener to be a object, instead got type ${typeof data}`);
    }

    if (typeof eventType !== 'string' || eventType.length < 1) {
      throw new Error(`No event type is specified. (*) to listen on all events`);
    }
    this.trackEvent(data);
  }

  reportTrackingData(event) {
    this.trackingJson.activity_events = [...this.trackingHistory];
  }

  trackEvent(data) {
    let tempHistory = [];
    tempHistory = this.trackingHistory;
    tempHistory.push(data);
    this.trackingHistory = tempHistory;
    // console.log("data" + JSON.stringify(this.trackingHistory));
  }

  getHistory() {
    // this.trackingJson.end_time = '';
    this.trackingJson.end_time = dayjs(new Date().getTime()).format('HH:mm:ss');
    this.trackingJson.utm = getUtmSource();
    this.trackingJson.platform = getAgent();
    this.trackingJson.token = getLocalStorage('refreshToken', true);
    this.trackingJson.activity_events = this.trackingHistory;
    // console.log("getting history" + JSON.stringify(this.trackingJson));
    return this.trackingJson;
  }

  configureReferer(url) {
    let tempRefArr = [];
    tempRefArr = getLocalStorage('refer_url', []);
    if (tempRefArr && typeof tempRefArr == 'object' && tempRefArr.length > 0) {
      if (!tempRefArr[0]) {
        tempRefArr[0] = url;
      }
      if (url != tempRefArr[1]) {
        tempRefArr[1] = url;
      }
    } else {
      tempRefArr = [];
      tempRefArr[0] = url;
    }
    setLocalStorage('refer_url', tempRefArr);
    return tempRefArr[0];
  }

  clearAllData() {
    this.trackingJson.page = '';
    this.trackingJson.page_id = 0;
    this.trackingJson.referrer = 0;
    this.trackingJson.activity_events = [];
    this.trackingJson.start_time = '';
    this.trackingJson.end_time = '';
    this.trackingJson.utm = getUtmSource();
    this.trackingJson.platform = '';
    this.trackingJson.version = appVersion;
    this.trackingHistory = [];
  }

  reportData() {
    try {
      this.trackingJson.start_time = this.trackingHistory[0].timestamp;
    } catch (error) {
      this.trackingJson.start_time = 'Not_Found';
    }

    this.trackingJson.referrer = this.configureReferer(this.trackingJson.referrer);
    // console.log(local_token)
    if (getLocalStorage('isLoggedIn', false) && getLocalStorage('isLoggedIn', false) !== false) {
      sendTrackingReport(this.getHistory())
        .then((data) => {
          this.clearAllData();
          if (data.status_code == 200) {
            //alert("tracking reported");
          } else {
            //alert("tracking not reported");
          }
        })
        .catch((e) => console.log(e));
    } else {
      sendTrackingReportOpen(this.getHistory())
        .then((data) => {
          this.clearAllData();
          if (data.status_code == 200) {
            // console.log("tracking reported");
          } else {
            // console.log("tracking not reported");
          }
        })
        .catch((e) => console.log(e));
    }
  }

  sendAnalytics() {
    console.log('sending analytics-----');
    try {
      this.trackingJson.start_time = this.trackingHistory[0].timestamp;
    } catch (error) {
      this.trackingJson.start_time = 'Not_Found';
    }

    this.trackingJson.referrer = this.configureReferer(this.trackingJson.referrer);

    if (
      getLocalStorage('user', undefined)?.master_user_type_id !== '10' &&
      getLocalStorage('user', undefined)?.master_user_type_id !== '5'
    ) {
      try {
        // navigator?.sendBeacon(process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT, JSON.stringify(this.getHistory()));
        this.clearAllData();
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  sendAnalyticsOpenPage() {
    console.log('sending analytics//////////////  -');
    try {
      this.trackingJson.start_time = this.trackingHistory[0].timestamp;
    } catch (error) {
      this.trackingJson.start_time = 'Not_Found';
    }

    this.trackingJson.referrer = this.configureReferer(this.trackingJson.referrer);

    navigator.sendBeacon(`${apiUrl}openapi/tracking`, JSON.stringify(this.getHistory()));
    this.clearAllData();
  }

  reportDataOpenPage() {
    try {
      this.trackingJson.start_time = this.trackingHistory[0].timestamp;
    } catch (error) {
      this.trackingJson.start_time = 'Not_Found';
    }
    this.trackingJson.referrer = this.configureReferer(this.trackingJson.referrer);
    sendTrackingReportOpen(this.getHistory())
      .then((data) => {
        this.clearAllData();
        if (data.status_code == 200) {
          // console.log("tracking reported");
        } else {
          // console.log("tracking not reported");
        }
      })
      .catch((e) => console.log(e));
  }
}
