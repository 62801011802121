export const pageTitles = {
  channel: {
    channelLanding: 'Channels',
    channelManage: 'Manage Channels',
    channelProfile: 'Channel Profile',
    channelSugg: 'Channel Suggestion'
  },
  clinicalVideo: {
    videoDetail: 'Clinical Video Detail',
    landing: 'Clinical Videos',
    listing: 'Clinical Videos'
  },
  DnR: {
    activities: 'DnR Activities',
    discussForm: 'Start a Discussion',
    landing: 'Discuss & Refer',
    profile: 'Hospital Profile',
    referForm: 'Start a Referral',
    thankYou: 'DnR Thank You',
    reInitiate: 'ReInitiate a Discussion'
  },
  eBook: {
    details: 'eBook Detail',
    list: 'Ebooks'
  },
  grandRound: { details: 'Grand Round Details', list: 'Grand Rounds' },
  login: 'Login',
  medwiki: {
    details: 'MedWiki Detail',
    list: 'MedWiki Feed',
    landing: 'MedWiki'
  },
  profile: 'User Profile',
  cpdReview: 'Cpd Review',
  registration: 'Registration',
  share: {
    clVideo: 'Share Clinical Video',
    gr: 'Share Grand Round',
    medwiki: 'Share Medwiki',
    poll: 'Share Poll',
    quiz: 'Share Quiz',
    session: 'Share Session',
    survey: 'Share Survey',
    TelemedLite: 'TelemedLite',
    Profile: 'Profile',
    channel: 'Channel',
    ebook: 'Ebook',
    observership: 'Observership'
  },
  spq: {
    feed: 'SPQ Feed',
    details: 'SPQ Detail',
    question: 'Answer SPQ',
    review: 'SPQ Review',
    submit: 'SPQ Results'
  },
  session: {
    landing: 'Live CME',
    details: 'SessionDetails',
    invitation: 'Session Invitation',
    waiting: 'Session Waiting Room',
    multiday_landing: 'Multiday Session Landing',
    multiday_waiting: 'Multi day Session Waiting Room',
    multiday_live: 'Multi day Session Live'
  },
  Telemed: 'Telemed Lite',
  HelpAndSupport: 'help_support',
  Doctube: {
    listing: 'Doctube',
    dashboard: 'Doctube Home',
    upload: 'Doctube Upload',
    profile: 'Doctube Profile',
    doctubeShare: 'Doctube Share'
  },
  MultidaySession: 'Multiday Session',
  Unavailable: 'unavailable',
  NotFound: 'NotFound',
  AutoAuth: {
    AutoAuth: 'AutoAuth',
    AutoAuthContinue: 'AutoAuthContinue',
    AutoAuthDirect: 'AutoAuthDirect'
  },
  Observership: {
    landing: 'Observership',
    details: 'Observership Details',
    registration: 'Observership Registration',
    profile: 'Observership Profile',
    postOpportunity: 'Observership Post Opportunity',
    chat: 'Observership Chat',
    applyToObservership: 'Apply Observership',
    applicantsListing: 'Applicants Listing',
    applicationDetails: 'Observership Application Details'
  }
};
