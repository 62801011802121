// GLOBAL ACTIONS STRAT
export const BANNER_CREATIVE_SELECTED = 'BANNER_CREATIVE_SELECTED';
export const BANNER_COMPONENT_LOAD = 'BANNER_COMPONENT_LOAD';
export const BANNER_COMPONENT_UNLOAD = 'BANNER_COMPONENT_UNLOAD';
export const BANNER_GLOBAL_CAMPAIGN_RESPONSE = 'BANNER_GLOBAL_CAMPAIGN_RESPONSE';
export const BANNER_CONTENT_CAMPAIGN_RESPONSE = 'BANNER_CONTENT_CAMPAIGN_RESPONSE';
export const BANNER_COMPONENT_VIDEO_ERROR = 'BANNER_COMPONENT_VIDEO_ERROR';
export const NOT_FOUND = 'NOT FOUND';
export const DEFAULT_ID = 0;
export const CLICK_CARD = 'CLICK_CARD';
export const PAGE_VIEW = 'PAGE_VIEW';
export const EVENT = 'EVENT';
export const NOT_FROM_DEEPLINK = 'NOT_FROM_DEEPLINK';
export const MAX_VIEW_DURATION = '0';

export const CHANGE_TAB = 'CHANGE_TAB';
export const CHANGE_HEADER_MENU = 'CHANGE_HEADER_MENU';
export const CLICK_SHARE_MENU = 'CLICK_SHARE_MENU';
export const CLICK_BANNER = 'CLICK_BANNER';
export const CLOSE_PAGE_VIEW = 'CLOSE_PAGE_VIEW';
export const SCROLL = 'SCROLL';
export const DO_PAGINATION = 'DO_PAGINATION';
export const CLICK_INNER_PAGE_MENU = 'CLICK_INNER_PAGE_MENU';
export const CLICK_RELATED_CONTENT = 'CLICK_RELATED_CONTENT';
export const CLICK_SHARE = 'CLICK_SHARE';
export const CHANGE_INPUT = 'CHANGE_INPUT';
export const CHANGE_OPTIONS = 'CHANGE_OPTIONS';
export const REDIRECT = 'REDIRECT';
export const OPEN_NEW_TAB = 'OPEN_NEW_TAB';
export const SEARCH = 'SEARCH';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const DOWNLOAD_CONTENT = 'DOWNLOAD_CONTENT';
export const VAULT_PRESS = 'VAULT_PRESS';
export const LIKE_PRESS = 'LIKE_PRESS';
export const USER_RATING = 'USER_RATING';
// GLOBAL ACTION END
//SPQ ACTIONS START
export const CLICK_SPQ_POINT_MENU = 'CLICK_SPQ_POINT_MENU';
export const CLICK_SPQ_COMPLETED_MENU = 'CLICK_SPQ_COMPLETED_MENU';
export const CLICK_SPQ_PENDING_MENU = 'CLICK_SPQ_PENDING_MENU';
export const CLICK_BEGIN_BUTTON = 'CLICK_BEGIN_BUTTON';
export const CLICK_BACK_TO_SPQ = 'CLICK_BACK_TO_SPQ';
export const CLICK_VIEW_RESULT = 'CLICK_VIEW_RESULT';
//SPQ ACTIONS END

//TELEMED ACTIONS START
export const CLICK_OPEN_NOW = 'CLICK_OPEN_NOW';
//TELEMED ACTIONS END

//DISCUSS_AND_REFER ACTIONS START
export const CLICK_ACTIVITY_CARD = 'CLICK_ACTIVITY_CARD';
export const CLOSE_ACTIVITY_DETAILS_MODAL = 'CLOSE_ACTIVITY_DETAILS_MODAL';
export const OPEN_DIRECT_TAB = 'OPEN_DIRECT_TAB';
export const PROFILE_UPDATE_MODAL_CLOSE = 'PROFILE_UPDATE_MODAL_CLOSE';
export const PROFILE_UPDATE_MODAL_OPEN = 'PROFILE_UPDATE_MODAL_OPEN';

//DISCUSS_AND_REFER ACTIONS END

//session action start
export const CLICK_RESERVE_BUTTON = 'CLICK_RESERVE_BUTTON';
export const CONNECT_DOCTOR_CLICK = 'CONNECT_DOCTOR_CLICK';
export const ATTEND_SESSION_CLICK = 'ATTEND_SESSION_CLICK';
export const CLICK_CANCEL_SESSION = 'CLICK_CANCEL_SESSION';
export const CLICK_CANCEL_REASON = 'CLICK_CANCEL_REASON';
export const SELECT_FILTER_SPECIALITY = 'SELECT_FILTER_SPECIALITY';
export const SELECT_FILTER_SESSION_TYPE = 'SELECT_FILTER_SESSION_TYPE';
export const CLICK_FILTER = 'CLICK_FILTER';
export const SESSION_INVITATION_SIGN_UP_CLICK = 'SESSION_INVITATION_SIGN_UP_CLICK';
export const SESSION_INVITATION_ERROR = 'SESSION_INVITATION_ERROR';
//session action end

//ask question start

export const CLICK_DETAILS = 'CLICK_DETAILS';
export const CLICK_CANCEL_QUESTION = 'CLICK_I_CANT_ATTEND';
//ask question end

//feed action
export const CLICK_VIDEOS_ONLY_CHECKBOX = 'CLICK_VIDEOS_ONLY_CHECKBOX';
export const CLICK_SELECT_SPECIALITY_BUTTON = 'CLICK_SELECT_SPECIALITY_BUTTON';
export const CLICK_VIEW_ALL_BUTTON = 'CLICK_VIEW_ALL_BUTTON';
export const CLICK_VIEW_MORE_BUTTON = 'CLICK_VIEW_MORE_BUTTON';
export const CLICK_SUBMIT_COMMENT_BUTTON = 'CLICK_SUBMIT_COMMENT_BUTTON';
export const CLICK_SUBMIT_REPLY = 'CLICK_SUBMIT_REPLY';
// export const NOT_FOUND = "NOT FOUND";
// export const DEFAULT_ID = 0;

// export const CLICK_RELATED_CONTENT = "CLICK_RELATED_CONTENT";
// export const CLICK_SHARE = "CLICK_SHARE";

//dahsboard type

export const CLICK_SUBMIT_POLL = 'CLICK_SUBMIT_POLL';
export const ADD_POINT = 'ADD_POINT';
export const GET_POLL_ANSWER = 'GET_POLL_ANSWER';
export const RENDER_POLL_RESULT = 'RENDER_POLL_RESULT';
export const SELECT_QUESTION_OPTION = 'SELECT_QUESTION_OPTION';
//search

export const CLICK_SEARCH = 'CLICK_SEARCH';

//profile

export const CLICK_EDIT_PROFILE = 'CLICK_EDIT_PROFILE';
export const CLICK_ADD_PROFILE = 'CLICK_ADD_PROFILE';
export const CLICK_DELETE_PROFILE = 'CLICK_DELETE_PROFILE';

//SHARE JS

export const CLICK_LOGIN_BUTTON = 'CLICK_LOGIN_BUTTON';
export const CLICK_REGISTER_BUTTON = 'CLICK_REGISTER_BUTTON';

//VERIFY JS

export const CLICK_VERIFY_OTP = 'CLICK_VERIFY_OTP';
export const CLICK_RESEND_OTP = 'CLICK_RESEND_OTP';
export const CLICK_GET_CALL_INSTEAD = 'CLICK_GET_CALL_INSTEAD';
export const MASTERDOCTOR_CLICK = 'MASTERDOCTOR_CLICK';

export const ATTCH_FILE_CLICK = 'ATTCH_FILE_CLICK';
export const SUBMIT_SESSION_CLICK = 'SUBMIT_SESSION_CLICK';

//contributor

export const ON_FOLLOW_CLICK = 'ON_FOLLOW_CLICK';
export const ACCESS_REQUESTED = 'ACCESS_REQUESTED';
export const ON_UNFOLLOW_CLICK = 'ON_UNFOLLOW_CLICK';
export const CLICK_VIEW_ALL_SUGGESTION = 'CLICK_VIEW_ALL_SUGGESTION';
export const ON_MANAGE_CLICK = 'ON_MANAGE_CLICK';

export const VIDEO_TRACKING = 'VIDEO_TRACKING';
export const ON_VIEW_ALL_BUTTON_CLICK = 'ON_VIEW_ALL_BUTTON_CLICK';
//epub

export const MOVE_TO_NEXT_PAGE = 'MOVE_TO_NEXT_PAGE';
export const SELECT_EBOOK_CARD = 'SELECT_EBOOK_CARD';

//Autoauth
export const AUTOAUTH_SIGN_UP_CLICK = 'AUTOAUTH_SIGN_UP_CLICK';
export const AUTOAUTH_ERROR = 'AUTOAUTH_ERROR';

// worker action

export const INNITIALIZE_PAGE_CONFIG = 'INNITIALIZE_PAGE_CONFIG';
export const TRACK_ACTIVITY_EVENTS = 'TRACK_ACTIVITY_EVENTS';
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';

export const SPECIALITY_CLICK = 'SPECIALITY_CLICK';

// user asked question in help and support
export const USER_ASKED_QUESTION_CLICK = 'USER_ASKED_QUESTION_CLICK';

export const CLICK_CTA_CARD = 'CLICK_CTA_CARD';

// FRESHCHAT_WIDGET
export const WIDGET_HAS_BEEN_OPENED = 'WIDGET_HAS_BEEN_OPENED';
export const WIDGET_HAS_BEEN_CLOSED = 'WIDGET_HAS_BEEN_CLOSED';
export const WIDGET_HAS_BEEN_LOADED = 'WIDGET_HAS_BEEN_LOADED';
export const USER_HAS_BEEN_CREATED = 'USER_HAS_BEEN_CREATED';
export const USER_RECIVE_A_MESSAGE = 'USER_RECIVE_A_MESSAGE';
export const AGENT_HAS_SENT_A_MESSAGE = 'AGENT_HAS_SENT_A_MESSAGE';
export const FRESHCHAT_WIDGET_REMOVED = 'FRESHCHAT_WIDGET_REMOVED';
export const FILE_DOWNLODED_BY_USER = 'FILE_DOWNLODED_BY_USER';

// Doctube
export const OPEN_ANALYTICS_MODAL = 'OPEN_ANALYTICS_MODAL';
export const CLOSE_ANALYTICS_MODAL = 'CLOSE_ANALYTICS_MODAL';
export const APPROVED_VIDEOS_EXPLORE_MORE = 'APPROVED_VIDEOS_EXPLORE_MORE';
export const REELS_VIDEOS_EXPLORE_MORE = 'REELS_VIDEOS_EXPLORE_MORE';
