import React from 'react';
import { reactHtmlParser } from '../../common/common';
import { Helmet } from 'react-helmet-async';

export const Seo = ({
  title = 'CLIRNET',
  description = "CLIRNET is India's largest Live CME and doctor generated content platform!",
  image = 'https://assets.clirnet.com/common/og_tag_logo.jpeg',
  url = 'https://doctor.clirnet.com/'
}) => {
  return (
    <Helmet
      title={reactHtmlParser(title)}
      htmlAttributes={{ lang: 'en_US' }}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'twitter:card',
          content: 'summary'
        },
        {
          property: 'twitter:creator',
          content: 'CLIRNET'
        },
        {
          property: 'twitter:title',
          content: title
        },
        {
          property: 'twitter:description',
          content: description
        },
        {
          property: 'twitter:image',
          content: image
        },
        {
          property: 'og:url',
          content: url
        },
        {
          property: 'og:type',
          content: 'article'
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:image',
          content: image
        }
        // {
        //   property: "fb:app_id",
        //   content: someFbAppId,
        // },
      ]}
    />
  );
};
