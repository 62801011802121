//firebase
import firebase from 'firebase/compat/app';

import { getLocalStorage, log } from './common';
import { toast } from 'react-toastify';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString
} from 'firebase/storage';

const firebase_config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID
};
firebase.initializeApp(firebase_config);

// export const database = firebase.database();

export const firebaseUpload = (
  e,
  firebaseToken,
  fileTypeArray,
  progressCb,
  callback,
  errCallBack
) => {
  console.log('HEY', e, firebaseToken);
  if (e && firebaseToken) {
    const storage = getStorage();

    const imageUrl = e;
    console.log('file', imageUrl.name);
    const unixTime = Math.round(new Date().getTime() / 1000); // Use getTime() for milliseconds
    const rand = Math.floor(Math.random() * 100000 + 1);
    const ext = imageUrl.name.split('.').pop().toLowerCase(); // Extract image extension

    const userDetails = getLocalStorage('user', {});
    console.log('USER', userDetails.user_master_id);
    const filename = `${userDetails.user_master_id}-${unixTime}-${rand}.${ext}`; // Use template literal for string formatting

    if (fileTypeArray.includes(ext)) {
      signInWithCustomToken(getAuth(), firebaseToken) // Use getAuth() instead of firebase.auth()
        .then(() => {
          // Generate unique name

          const uploadTask = uploadBytesResumable(
            ref(
              storage,
              `${process.env.REACT_APP_API_URL_IMG_FOLDER}${userDetails?.user_master_id}/${filename}`
            ),
            imageUrl
          );
          console.log(uploadTask);
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              progressCb(Math.round(progress));
            },
            (error) => {
              log('error', error);
            },
            () => {
              getDownloadURL(
                ref(
                  storage,
                  `${process.env.REACT_APP_API_URL_IMG_FOLDER}${userDetails?.user_master_id}/${filename}`
                )
              )
                .then((url) => {
                  callback(url, filename);
                })
                .catch((e) => console.log(e));
            }
          );
        })
        .catch((error) => {
          log('error', error);
        });
    } else {
      toast.error(`Allowed File Extensions : ${fileTypeArray.join()}`);
      errCallBack();
    }
  }
};

export const CapacitorFirebaseUpload = (
  e,
  firebaseToken,
  fileTypeArray,
  progressCb,
  callback,
  errCallBack
) => {
  if (e && firebaseToken) {
    const storage = getStorage(); // Get a reference to the storage service

    const imageUrl = e;
    const unixTime = Math.round(new Date().getTime() / 1000); // More accurate timestamp
    const randomNum = Math.floor(Math.random() * 100000 + 1);
    const ext = imageUrl.name.split('.').pop().toLowerCase(); // Extract image extension
    const userDetails = getLocalStorage('user', {});
    const filename = `${userDetails?.user_master_id}${unixTime}${randomNum}.${ext}`;

    if (fileTypeArray.includes(ext)) {
      signInWithCustomToken(getAuth(), firebaseToken) // Authenticate with Firebase
        .then(async () => {
          try {
            // Create a reference to the upload location
            const imageRef = ref(
              storage,
              `${process.env.REACT_APP_API_URL_IMG_FOLDER}${userDetails?.user_master_id}/${filename}`
            );

            // Upload the image data as base64, specifying content type
            const uploadTask = await uploadString(imageRef, imageUrl.data, 'base64', {
              contentType: 'image/jpeg'
            });

            // Get the download URL after successful upload
            const url = await getDownloadURL(uploadTask.ref);
            callback(url, filename); // Handle success
          } catch (error) {
            console.error('Upload error:', error);
            log('uploadTask error', JSON.stringify(error)); // Log for debugging
            errCallBack(); // Handle error
          }
        })
        .catch((error) => {
          console.error('Authentication error:', error);
          log('my firebase error', JSON.stringify(error));
          errCallBack(); // Handle error
        });
    } else {
      toast.error(`Allowed File Extensions : ${fileTypeArray.join()}`);
      errCallBack();
    }
  }
};

export const firebaseUploadTest = (
  e,
  firebaseToken,
  fileTypeArray,
  progressCb,
  callback,
  errCallBack
) => {
  console.log('HEY', e);
  if (e) {
    const storage = getStorage();

    const imageUrl = e; // Assuming 'e' contains the image data (e.g., a File object)
    console.log('file', e);
    const unixTime = Math.round(new Date().getTime() / 1000); // More precise timestamp generation
    const rand = Math.floor(Math.random() * 100000 + 1);
    const ext = imageUrl.name.split('.').pop().toLowerCase(); // Extract image extension

    const userDetails = getLocalStorage('user', {});
    console.log('USER', userDetails.user_master_id);
    const filename = `${userDetails.user_master_id + unixTime + rand}.${ext}`;
    console.log('FILE NAME', filename);

    if (fileTypeArray.includes(ext)) {
      const imageRef = ref(
        storage,
        `${process.env.REACT_APP_API_URL_IMG_FOLDER}${userDetails?.user_master_id}/${filename}`
      );

      uploadBytes(imageRef, imageUrl) // Assuming 'imageUrl.data' contains the image data
        .then((snapshot) => {
          console.log('Upload complete');
          return getDownloadURL(snapshot.ref); // Return the download URL promise
        })
        .then((downloadURL) => {
          callback(downloadURL, filename);
        })
        .catch((error) => {
          console.error('Upload error:', error);
          log('error', error); // Assuming 'log' is a custom logging function
          errCallBack(); // Handle upload error gracefully (e.g., show error message)
        });
    } else {
      toast.error(`Allowed File Extensions : ${fileTypeArray.join()}`);
      errCallBack();
    }
  }
};
