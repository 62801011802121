import React, { memo } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Pagination } from 'swiper/modules';

import LazyImage from '../../../components/CustomLibraries/LazyImage';
const SponsorLogoSwiper = ({ sponsorLogo }) => {
  return sponsorLogo.length > 0 ? (
    <Swiper
      className="shareLogineV2sponser float-end m-0 z-3"
      navigation={false}
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500
      }}
      effect={'fade'}
    >
      {sponsorLogo.map((_s, index) => (
        <SwiperSlide className="shareLogineV2sponserIn position-relative w-100" key={index + 1}>
          <LazyImage
            className="mw-100 mh-100 w-auto h-auto position-absolute top-50 end-0 translate-middle-y"
            src={_s}
            alt="sponsor"
            logo
          />
        </SwiperSlide>
      ))}
    </Swiper>
  ) : null;
};

export default memo(SponsorLogoSwiper);
