import axios from 'axios';
import { GET_CONTENT_CAMPAIGN_DATA } from '../apis/banner.apis';
import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { bannerData, contentCampaignData } from '../demoData';
import { sortBannerByType } from '../utils/sortBannerByType';
import { separateByPosition } from '../utils/separateByPosition';

const staleTime = 900000;
const cacheTime = 900000;

const fetchContentCampaign = async ({ queryKey }) => {
  const id = queryKey[1];
  const content_type = queryKey[2];

  return axiosInstance
    .get(`${GET_CONTENT_CAMPAIGN_DATA}?content_type=${content_type}&content_type_id=${id}`)
    .then((response) => {
      const data = separateByPosition(response.data.data);
      const timestamp_added = response.data.timestamp_added;
      return {
        allCampaigns: response.data.data,
        hasData: response.data.data.length > 0 ? true : false,
        data,
        timestamp_added
      };
    });
};

export const useContentCampaign = (id, content_type, position, excludedCampigns, enabled, type) => {
  return useQuery([GET_CONTENT_CAMPAIGN_DATA, id, content_type], fetchContentCampaign, {
    // select: (response) => response.data.data,
    select: (response) => {
      console.log('response', response);
      if (enabled && response && 'data' in response && position in response.data) {
        let bannerData = response.data[position];

        if (bannerData && Array.isArray(bannerData) && bannerData.length > 0 && position !== '') {
          const filterData = bannerData.filter(
            (_elem) =>
              _elem.target_type.toLowerCase() === type && !excludedCampigns?.ids.includes(id)
          );

          filterData.sort((a, b) => parseInt(b.ad_factor) - parseInt(a.ad_factor));
          console.log('hasContentCampaignData if', position, filterData);

          return {
            timestamp_added: response.timestamp_added,
            data: sortBannerByType(filterData),
            hasData: response.hasData
          };
        } else {
          console.log('hasContentCampaignData else', position);

          return {
            timestamp_added: response.timestamp_added,
            data: [],
            hasData: response.hasData
          };
        }
      } else {
        return {
          timestamp_added: '',
          data: [],
          hasData: response.hasData
        };
      }

      // var bannerData = contentCampaignData;
    },
    enabled: enabled,
    cacheTime: cacheTime,
    staleTime: staleTime,
    refetchOnWindowFocus: false
    // initialData: { data: [], timestamp_added_global: '' }
  });
};
