import React, { memo } from 'react';
import { Skeleton } from '../../Skeleton';

const LeftSideLoader = () => {
  return (
    <div className="w-100 clr_v3_SpecialityLeft d-flex flex-column justify-content-between align-item-center">
      <div className="clr_v3_SpecialityLeft__profileContent d-flex justify-content-center flex-column p-4 rounded-4 border-light border-1 border">
        <div
          className="rounded-circle p-2 border border-2 position-relative mx-auto"
          style={{ borderColor: '#d9d9d9' }}
        >
          <div className="overflow-hidden icon-size-60 icon-size-sm-80 rounded-circle position-relative">
            <Skeleton
              variant="circular"
              width={50}
              height={50}
              className="object-fit-cover w-100 h-100 float-start"
            />
          </div>
        </div>

        <div className="m-auto mt-3">
          <Skeleton
            variant="text"
            width={100}
            height={30}
            className="fs-16 fw-bold mb-2 text-black cursorPointer"
          />
          <Skeleton variant="text" width={'100%'} height={15} className="mb-2" />
        </div>

        <div className="d-flex flex-column justify-content-center gap-2">
          {[1, 2, 3, 4].map((i) => (
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between">
                <span className="fs-14 fw-normal">
                  <Skeleton variant={'text'} height={25} width={60} />
                </span>
                <span className="fs-14 fw-semibold">
                  <Skeleton variant={'text'} height={25} width={40} />
                </span>
              </div>
              <Skeleton key={i} variant="text" width="100%" height={10} />
            </div>
          ))}
        </div>
        <Skeleton
          variant="rounded"
          width="100%"
          height={50}
          className="rounded-pill gap-2 w-100 mt-5"
        />
      </div>

      <div className="clr_v3_SpecialityLeft__footer d-flex flex-column gap-5 mt-50">
        <ul className="clr_v3_SpecialityLeft__footer__list d-flex flex-column gap-2 align-items-start list-unstyled">
          <li>
            <Skeleton variant="text" width={100} height={15} />
          </li>
          <li>
            <Skeleton variant="text" width={100} height={15} />
          </li>
          <li>
            <Skeleton variant="text" width={100} height={15} />
          </li>
        </ul>
        <div className="d-flex flex-row gap-2">
          {[1, 2, 3, 4, 5].map((i) => (
            <Skeleton key={i} variant="clircular" width={30} height={30} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(LeftSideLoader);
