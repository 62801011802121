import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import packageData from '../../../../package.json';
import useRedirect from '../../customHooks/useRedirect';
import { routeNames } from '../../../router/constants';

function SideBarCTA() {
  const userRegionState = useSelector((state) => state.share.UserRegion);

  const { redirectTo } = useRedirect();

  const onHelpAndSupportClick = () => {
    redirectTo(routeNames.helpAndSupport.landing);
  };

  return (
    <>
      <div className="clr_v3_SpecialityLeft__footer d-flex flex-column gap-lg-4 gap-3 bg-extra-light p-3 p-sm-4 rounded-3">
        <ul className="clr_v3_SpecialityLeft__footer__list d-flex flex-column gap-2 align-items-start list-unstyled  m-0">
          <li>
            <a className="text-black" href="javascript:void(0)" onClick={onHelpAndSupportClick}>
              Help & Support
            </a>
          </li>
          <li>
            <a
              className="text-black"
              target="_blank"
              href={
                userRegionState === 'GT' || userRegionState === 'GL'
                  ? process.env.REACT_APP_TERMS_CONDITIONS_LINK_GT
                  : process.env.REACT_APP_TERMS_CONDITIONS_LINK
              }
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              className="text-black"
              target="_blank"
              href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
            >
              Privacy Policy
            </a>
          </li>
        </ul>
        <div className="d-flex flex-row gap-2">
          <a
            href={process.env.REACT_APP_FACEBOOK_LINK}
            target="_blank"
            className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
          >
            <i className="flaticon-facebook-app-symbol fs-4"></i>
          </a>
          <a
            href={process.env.REACT_APP_TWITTER_LINK}
            target="_blank"
            className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
          >
            <i className="flaticon-twitter fs-4"></i>
          </a>
          <a
            href={process.env.REACT_APP_INSTAGRAM_LINK}
            target="_blank"
            className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
          >
            <i className="flaticon-instagram fs-4"></i>
          </a>
          <a
            href={process.env.REACT_APP_LINKEDIN_LINK}
            target="_blank"
            className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
          >
            <i className="flaticon-linkedin fs-4"></i>
          </a>
          <a
            href={process.env.REACT_APP_YOUTUBE_LINK}
            target="_blank"
            className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
          >
            <i className="flaticon-youtube fs-4"></i>
          </a>
        </div>
      </div>
      <h4 className="fs-16 text-black fw-bold ">CLIRNET v-{packageData.version}</h4>
    </>
  );
}

export default memo(SideBarCTA);
