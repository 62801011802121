import React, { forwardRef } from 'react';
import '../../../assets/sass/uiV3/component/_card.scss';
import { Button } from 'react-bootstrap';
const Card = forwardRef((props, ref) => (
  <div
    ref={ref}
    className={`${
      props.type == 'small' ? 'clr_v3_card--small' : ''
    } clr_v3_card position-relative py-3 py-sm-4 rounded-4 overflow-hidden text-start bg-white ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </div>
));
Card.Header = (props) => (
  <div
    className={`clr_v3_card__header d-flex align-items-center text-black justify-content-between mb-3 ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </div>
);
Card.Body = (props) => (
  <div
    className={`clr_v3_card__body d-flex flex-column px-sm-4 px-3 ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </div>
);
Card.Footer = (props) => (
  <div className="clr_v3_card__footer d-flex align-items-center text-black justify-content-between mt-4">
    {props.children}
  </div>
);
Card.Title = (props) => (
  <h2
    className={`fs-16 text-black line-clamp-2 fw-semibold clr_v3_card__title ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </h2>
);
Card.Description = (props) => (
  <h4
    className={`fs-4 text-dark clr_v3_card__description ${
      props?.showFullText ? '' : 'line-clamp-2'
    } fw-normal mt-1 ${props.className ? props.className : ''}`}
  >
    {props.children}
  </h4>
);
Card.Image = forwardRef((props, ref) => (
  <div
    ref={ref}
    className={`clr_v3_coverImage position-relative rounded-3 overflow-hidden flex-shrink-0 ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </div>
));
Card.Button = (props) => (
  <Button variant='primary'
    className={`flex-grow-1 ${props.className ? props.className : ''}`}
    onClick={props.onClick ? props.onClick : () => {}}
  >
    {props.children}
  </Button>
);

export default Card;
