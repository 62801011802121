import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import {
  GET_MENTOR_DETAILS,
  GET_OBSERVERSHIP_DETAILS,
  GET_OBSERVERSHIP_LIST,
  GET_CHAT_LIST,
  GET_MY_APPLICATIONS,
  BOOKED_SLOTS,
  GET_APPLICANTS_LISTING,
  MY_POSTED_OBSERVERSHIPS,
  GET_APPLICATION_DETAILS,
  GET_TEMPLATE_DATA,
  DRAFTED_OBSERVERSHIPS
} from '../QueryKeys/observership.key';
import axiosInstance from '../../Store/helper/axiosInstance';
import dayjs from 'dayjs';

const staleTime = 300000;
const cacheTime = 600000;

export const useGetObservershipListing = (
  speciality = '',
  type = 'all',
  country_id = '',
  mentor_id = '',
  enabled = true
) => {
  return useInfiniteQuery(
    [GET_OBSERVERSHIP_LIST, speciality, type, country_id],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `observership/list?from=${pageParam}&to=10&speciality=${
          speciality ? speciality : ''
        }&type=${type ? (type == 'observership' ? 'observer' : 'mentor') : 'all'}&country_id=${
          country_id ? country_id : ''
        }&mentor_id=${mentor_id ? mentor_id : ''}`
      ),

    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          // console.log(allPages.length);
          return allPages.length * 10;
        } else {
          return undefined;
        }
      },

      select: (data) => ({
        pages: data.pages.flatMap((x) => x),

        pageParams: data.pageParams
      }),
      // staleTime: staleTime,
      cacheTime: cacheTime
      // enabled: !!speciality || speciality != 0
    }
  );
};
export const useMyPostedObserverships = (enabled = true) => {
  return useInfiniteQuery(
    [MY_POSTED_OBSERVERSHIPS],
    ({ pageParam = 0 }) => axiosInstance.get(`observership/myposts?from=${pageParam}&to=5`),

    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length >= 5) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x),
        pageParams: data.pageParams
      }),
      cacheTime: cacheTime
    }
  );
};
export const useMyApplications = (enabled = true) => {
  return useInfiniteQuery(
    [GET_MY_APPLICATIONS],
    ({ pageParam = 0 }) =>
      axiosInstance.get(`observership/myapplicationlist?from=${pageParam}&to=5`),

    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length >= 5) {
          // console.log(allPages.length);
          return allPages.length * 5;
        } else {
          return undefined;
        }
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x),
        pageParams: data.pageParams
      }),
      // staleTime: staleTime,
      cacheTime: cacheTime
      // enabled: !!speciality || speciality != 0
    }
  );
};

export const useGetTemplateData = (type = '') => {
  return useQuery(
    [GET_TEMPLATE_DATA, type],
    () => axiosInstance.get(`observership/get_template_data?type=${type}`),

    {
      refetchOnWindowFocus: false,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!type
    }
  );
};
export const useGetMentorDetails = (mentor_id, onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useQuery(
    [GET_MENTOR_DETAILS, mentor_id],
    () => axiosInstance.get(`mentor/detail?mentor_id=${mentor_id}`),

    {
      refetchOnWindowFocus: false,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!mentor_id,
      onSuccess,
      initialData: () => {
        const observershipListQueries = queryClient.getQueriesData(GET_OBSERVERSHIP_LIST);
        const mentorshipData =
          observershipListQueries.length > 0
            ? observershipListQueries
                ?.map((query) => {
                  if (query[1] && query[1]?.pages && query[1]?.pages?.length > 0) {
                    console.log('contentEachQueryData', query[1]);
                    return query[1]?.pages?.map((page) => page?.data?.data)?.flat();
                  }
                })
                ?.flat()
            : [];

        const mentor = mentorshipData?.find((_m) => {
          if (_m) {
            let type = _m.type || _m.trending_type;
            let type_id = _m.sessions_doctors_id || _m.mentor_id;
            return type == 'mentor' && type_id == mentor_id;
          }
        });
        if (mentor) {
          console.log('getMentorDetails', mentor);
          return {
            data: { data: { ...mentor, mentor_user_master_id: mentor?.user_master_id || '' } }
          };
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useGetObservershipDetails = (
  observership_id,
  onSuccess = () => {},
  onError = () => {}
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [GET_OBSERVERSHIP_DETAILS, observership_id],
    () => axiosInstance.get(`observership/detail?observership_id=${observership_id}`),

    {
      refetchOnWindowFocus: false,
      select: (response) => response.data.data,
      cacheTime: cacheTime,
      enabled: !!observership_id,
      onSuccess,
      onError,
      initialData: () => {
        const observershipListQueries = queryClient.getQueriesData(GET_OBSERVERSHIP_LIST);
        const mentorshipData =
          observershipListQueries.length > 0
            ? observershipListQueries
                ?.map((query) => {
                  if (query[1] && query[1]?.pages && query[1]?.pages?.length > 0) {
                    console.log('contentEachQueryData', query[1]);
                    return query[1]?.pages?.map((page) => page?.data?.data)?.flat();
                  }
                })
                ?.flat()
            : [];

        const observership = mentorshipData?.find((_m) => {
          if (_m) {
            let type = _m.type || _m.trending_type;
            let type_id = _m.id;
            return type == 'observer' && type_id == observership_id;
          }
        });
        if (observership) {
          console.log('observership from initial data', observership);
          return { data: { data: { ...observership } } };
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useGetChatList = (mentor_text = '') => {
  return useQuery(
    [GET_CHAT_LIST, mentor_text],
    () =>
      !mentor_text
        ? axiosInstance.get(`mentor/roomUserlist`)
        : axiosInstance.post(`mentor/search_a_room_user`, {
            key_word: mentor_text,
            to: '10',
            from: '0'
          }),

    {
      refetchOnWindowFocus: false,
      select: (response) => response.data.data,
      cacheTime: cacheTime
    }
  );
};

export const useGetDraftedObserverships = (enabled = true) => {
  return useQuery(
    [DRAFTED_OBSERVERSHIPS],
    () => axiosInstance.get(`observership/fetch_a_mentor_draft_observership`),

    {
      refetchOnWindowFocus: false,
      select: (response) => {
        const responseData = response?.data?.data;
        if (responseData && Array.isArray(responseData)) {
          return responseData?.map((x) => ({ ...x, draft: true }));
        } else if (responseData && !Array.isArray(responseData)) {
          return [{ ...responseData, draft: true }];
        } else {
          return [];
        }
      },
      cacheTime: cacheTime,
      enabled
    }
  );
};

export const useBookedSlots = (mentor_id) => {
  return useQuery(
    [BOOKED_SLOTS, mentor_id],
    () => axiosInstance.get(`mentor/slot?to_id=${mentor_id}`),

    {
      refetchOnWindowFocus: false,
      select: (response) =>
        response?.data?.data?.length > 0
          ? response.data.data?.map((x) => ({
              mentee_id: x.from_id,
              date: dayjs(x.start_date_time).format('YYYY-MM-DD'),
              slotFrom: dayjs(x.start_date_time).format('HH:mm'),
              slotTo: dayjs(x.end_date_time).format('HH:mm')
            }))
          : [],
      // staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!mentor_id
    }
  );
};

export const useGetObservershipApplicants = (observership_id) => {
  return useInfiniteQuery(
    [GET_APPLICANTS_LISTING, observership_id],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `observership/requests?from=${pageParam}&to=5&observership_id=${observership_id}`
      ),

    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length >= 5) {
          // console.log(allPages.length);
          return allPages.length * 5;
        } else {
          return undefined;
        }
      },
      select: (data) => ({
        pages: data.pages.flatMap((x) => x),
        pageParams: data.pageParams
      }),
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!observership_id
    }
  );
};
export const useGetApplicationDetails = (request_id, onSuccessRequestDetails) => {
  return useQuery(
    [GET_APPLICATION_DETAILS, request_id],
    () => axiosInstance.get(`observership/requestDetail?request_id=${request_id}`),
    {
      refetchOnWindowFocus: false,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      enabled: !!request_id,
      onSuccess: onSuccessRequestDetails,
      onError: onSuccessRequestDetails
    }
  );
};
