import React, { memo } from 'react'
import { Skeleton } from '../../../../../skeleton/Skeleton'

function ObservershipCardSkeleton() {
  return (
    <div className='clr_v3_card--small clr_v3_card position-relative overflow-hidden d-flex gap-4 gap-md-4 bg-white rounded-4 flex-row clr_v3_card--observership'>
        <div className='clr_v3_card__container flex-grow-1 w-100 p-0 p-md-0 pt-0 pt-md-0 d-flex flex-row gap-3 gap-md-4'>
            <div className='clr_v3_card__imageContainer  flex-shrink-0 d-flex align-items-start '>
                <div className='clr_v3_coverImage bg-soft-dark cursorPointer position-relative overflow-hidden rounded-3 '>
                    <Skeleton variant={'rectangular'} className='object-fit-cover w-100 h-100 transition' />
                </div>
            </div>
            <div className='clr_v3_card__body d-flex row-gap-2 column-gap-4 column-gap-md-4 flex-column flex-grow-1 ps-0 p-4 pt-2 pt-md-3'>
                <div className='clr_v3_card__header align-items-center justify-content-between d-flex flex-row gap-3 '>
                    <div className='d-flex align-items-center clr_v3_card__typeTag gap-2 '>
                        <Skeleton variant={'circular'} height={17} width={17} />
                        <Skeleton variant={'text'} height={20} width={65} />
                    </div>
                    <div className='align-items-center d-flex gap-2 gap-md-3'>
                        <div className='d-flex align-items-center clr_v3_card__typeTag gap-2 '>
                        <Skeleton variant={'circular'} height={19} width={19} />
                        <Skeleton variant={'text'} height={20} width={55} />
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row gap-4 align-items-end'>
                    <div className='clr_v3_card__content flex-grow-1 cursorPointer shineEffect-active d-flex flex-column gap-1 gap-md-1'>
                        <h4>
                        <Skeleton variant={'text'} height={30} width={150} />
                        </h4>
                        <h4>
                        <Skeleton variant={'text'} height={20} width={70} />
                        </h4>
                        <div className='d-flex justify-content-between flex-wrap gap-3'>
                            <h4>
                            <Skeleton variant={'text'} height={20} width={90} />  
                            </h4>
                        </div>
                        <div className='d-flex align-items-center gap-3 mt-2 flex-column flex-md-row'></div>

                    </div>
                    
                    <Skeleton variant={'text'} height={45} width={90} className='d-flex flex-fill align-item-center justify-content-center gap-3 gap-md-3 py-2 text-primary px-4 fw-medium text-nowrap flex-shrink-0 btn btn-outline-light'/> 
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default memo(ObservershipCardSkeleton);