export function separateByPosition(inputArray) {
  const outputObject = {};

  inputArray.forEach((item) => {
    let positions;

    // Check if position is a comma-separated string
    if (typeof item.position === 'string' && item.position.includes(',')) {
      positions = item.position.split(',').map((pos) => pos.trim());
    } else {
      positions = [item.position];
    }

    positions.forEach((position) => {
      if (!outputObject[position]) {
        outputObject[position] = [];
      }

      outputObject[position].push(item);
    });
  });

  return outputObject;
}
