import React, { memo } from 'react'
import { Skeleton } from '../../../../../skeleton/Skeleton'

function ApplicationDetailsSkeleton() {
  return (
    <div className='p-4 bg-white rounded'>
        <div className='px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent'>
            <h2 className='mb-4 text-start lh-1 text-black fw-semibold fs-2'>
                <Skeleton variant='text' height={20} width={30} />
            </h2>
            </div>
        </div>
  )
}

export default memo(ApplicationDetailsSkeleton);