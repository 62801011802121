import { Capacitor } from '@capacitor/core';
import OneSignalCordova from 'onesignal-cordova-plugin';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { postOneSignalPlayerId } from '../Store/actions';
import { setLocalStorage } from '../common/common';

const OnesignalAppInit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const OneSignalInit = () => {
    // console.log("Inside mobile notifications: ");
    OneSignalCordova.setAppId('ece3239e-917d-4b4a-8bc6-08791d7d0028'); //live_env
    // OneSignalCordova.setAppId('e9a83fc9-feab-4ffa-82de-c0ddcf6daf23'); //dev_env

    OneSignalCordova.promptForPushNotificationsWithUserResponse(function (accepted) {
      // console.log("User accepted notifications: " + accepted);
    });

    OneSignalCordova.addSubscriptionObserver(({ to }) => {
      // console.log('OneSignal: subscription changed:', to);
      // alert('Subscription Observer OneSignal Player ID :: ==>' + to.userId);
      to?.userId && setLocalStorage('app_one_signal_player_id', to?.userId);
      let userId = to?.userId;
      if (userId) {
        setLocalStorage('one_signal_player_id', userId);
        //user_master_id,onesignal,os,appversion,browser,browserversion
        let formData = new FormData();
        // formData.append('user_master_id', user_master_id());
        formData.append('onesignal', userId);
        formData.append('os', Capacitor.getPlatform());
        formData.append('appversion', '1.0.0');
        formData.append('browser', 'null');
        formData.append('browserversion', 'null');
        // console.log("my from data-->", JSON.stringify(formData));
        dispatch(
          postOneSignalPlayerId(formData, (res) => {
            // console.log('RESPONSE onesignal id post', JSON.stringify(res));
          })
        );
      }
    });

    // OneSignalCordova.setNotificationWillShowInForegroundHandler(function (jsonData) {
    //     if (jsonData) {
    //         console.log("my foreground json data", JSON.stringify(jsonData));
    //         let myUri = new URL(jsonData.notification.launchURL)
    //         navigate(myUri.hash.replace('#', ''))
    //     }
    // });

    // OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent) => {
    //     console.log("my foreground json data", JSON.stringify(notificationReceivedEvent));
    //     const notification = notificationReceivedEvent.getNotification();
    //     notificationReceivedEvent.complete(notification);
    //     let myUri = new URL(notification.notification.launchURL)
    //     navigate(myUri.hash.replace('#', ''))
    // });
    OneSignalCordova.setNotificationOpenedHandler(function (jsonData) {
      if (jsonData) {
        // alert("my background json data" + JSON.stringify(jsonData));
        let myUri = new URL(jsonData.notification.launchURL);
        // console.log("my notification uri", myUri.hash);
        navigate(myUri?.hash?.replace('#', ''));
      }
    });

    OneSignalCordova.setLaunchURLsInApp(false);
    // OneSignalCordova.clearOneSignalNotifications()
    // OneSignalCordova.removeNotification()
  };

  useEffect(() => {
    OneSignalInit();
  }, []);

  return <></>;
};

export default OnesignalAppInit;
