import React, { memo } from 'react';

const Divider = ({ height = 2, className = '', position = 'left' }) => {
  return (
    <div
      className={
        'clr_v3_TtlDivider position-relative bg-light rounded overflow-hidden mb-3 ' +
        className +
        ' ' +
        (position == 'left' ? 'me-auto' : position == 'right' ? 'ms-auto' : 'mx-auto')
      }
      style={{ height: height + 'px' }}
    >
      <span className="w-50 bg-primary position-absolute top-0 start-0 h-100"></span>
    </div>
  );
};

export default memo(Divider);
