import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../../assets/sass/uiV3/pages/_specialityDefaultLayout.scss';
import HeaderProfileMobile from '../../components/baseUiV3/template/header/HeaderProfileMobile';
import HeaderSpecialityDetailsMobile from '../../components/baseUiV3/template/header/HeaderSpecialityDetailsMobile';
import HeaderSpecialityDesktop from '../../components/baseUiV3/template/header/HeaderSpecialityDesktop';
import { isMobile } from 'react-device-detect';
import { Row } from 'react-bootstrap';
import { routeNames } from '../../router/constants';
import { isACommendableRoute } from '../../common/common';
import { useSelector } from 'react-redux';

function SpecialityDefaultLayout() {
  const [elHeight, setElheight] = useState(0);
  const commentsShowing = useSelector((state) => state.common.showComment);
  const getElementHeight = (data) => {
    // alert(data)
    setElheight(data);
  };
  const { pathname } = useLocation();
  const pageName = pathname.slice(1).toLowerCase();

  return (
    <>
      {isMobile ? (
        pageName == 'userProfile' ? (
          <HeaderProfileMobile getElementHeight={getElementHeight} />
        ) : (
          <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight} />
        )
      ) : (
        <HeaderSpecialityDesktop getElementHeight={getElementHeight} elHeight={elHeight} />
      )}
      <div
        className={`${
          isMobile ? 'mobile' : 'desktop'
        } speciality-portal speciality-portal--defaultLayout w-100 d-flex`}
        style={{
          paddingTop: `${elHeight}px`,
          paddingBottom: `${isACommendableRoute(pathname) && commentsShowing ? 75 : 0}px`
        }}
      >
        <div className="w-100 d-flex flex-column position-relative">
          <div className="container-fluid">
            <Row className="align-items-start flex-nowrap">
              <Outlet />
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialityDefaultLayout;
