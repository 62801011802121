import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../Store/helper/axiosInstance';
import { CERTIFICATE_DETAILS, CERTIFICATE_LIST } from '../QueryKeys/certificate.key';
import { generateUtmExt } from '../../common/common';

const staleTime = 300000;
const cacheTime = 600000;
//certificate/listing?from=${pagination}&to=${to}
export const useCertificateList = (onSuccess) => {
  return useInfiniteQuery(
    CERTIFICATE_LIST,
    ({ pageParam = 0 }) => axiosInstance.get(`certificate/listing?from=${pageParam}&to=10`),
    {
      staleTime: staleTime,
      refetchOnWindowFocus: false,
      cacheTime: cacheTime,
      onSuccess,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length == 10) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};
const fetchData = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`certificate/detail?type_id=${id}${generateUtmExt()}`);
};
export const useCertificateDetails = (id, enabled, onSuccess) => {
  const queryClient = useQueryClient();
  return useQuery([CERTIFICATE_DETAILS, id], fetchData, {
    enabled: !!enabled,
    onSuccess,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
    initialData: () => {
      const certificate = queryClient
        .getQueryData(CERTIFICATE_LIST)
        ?.pages?.map((page) => page?.data?.data)
        ?.flat()
        ?.find((e) => e.type_id == id);

      if (certificate) {
        return { data: { data: certificate } };
      } else {
        return undefined;
      }
    }
  });
};
const editCert = (data) => {
  return axiosInstance.post('certificate/update', data);
};
export const useEditCertificate = (cert_id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation(editCert, {
    onMutate: async (obj) => {
      console.log('RQ MUTATION', obj);

      const previousData = queryClient.getQueryData([CERTIFICATE_DETAILS, cert_id]);
      const { id, title, description, file, year } = obj;
      queryClient.setQueryData([CERTIFICATE_DETAILS, cert_id], (oldQueryData) => {
        // console.log('PREVIOUS', oldQueryData);
        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            data: {
              ...oldQueryData.data.data,
              title,
              description,
              file,
              year,
              type_id: id
            }
          }
        };
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSuccess
  });
};
