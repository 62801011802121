export const GET_OBSERVERSHIP_LIST = 'GET_OBSERVERSHIP_LIST';
export const GET_MENTOR_DETAILS = 'GET_MENTOR_DETAILS';
export const GET_OBSERVERSHIP_DETAILS = 'GET_OBSERVERSHIP_DETAILS';
export const GET_CHAT_LIST = 'GET_CHAT_LIST';
export const GET_MY_APPLICATIONS = 'GET_MY_APPLICATIONS';
export const BOOKED_SLOTS = 'BOOKED_SLOTS';
export const GET_APPLICANTS_LISTING = 'GET_APPLICANTS_LISTING';
export const MY_POSTED_OBSERVERSHIPS = 'MY_POSTED_OBSERVERSHIPS';
export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';
export const GET_TEMPLATE_DATA = 'GET_TEMPLATE_DATA';
export const DRAFTED_OBSERVERSHIPS = 'DRAFTED_OBSERVERSHIPS';
