import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { isMobileApp } from '../common/common';
import LoadingFallback from './fallback/LoadingFallback';
import AppRegister from '../pages/uiv3/appRegister/AppRegister';

function AuthRouter({ children, autoAuth = false }) {
  const userDetails = useSelector((state) => state.common.user_details);
  const location = useLocation();
  const { content_type, content_id } = useParams();
  const { autoAuth: autoAuthFromState } = location?.state || {};

  const { env, spec_dashboard_eligibility_status, specility_update_status, user_master_id } =
    userDetails || {};

  function navigateToUrl(content_type, content_id) {
    const contentType = content_type === 'medwiki' ? 'medwiki/detail' : content_type;
    return <Navigate to={`/${contentType}/${content_id}`} state={{ autoAuth: true }} />;
  }
  if (autoAuth) {
    return navigateToUrl(content_type, content_id);
  } else if (autoAuthFromState) {
    return children;
  } else {
    if (userDetails && user_master_id) {
      //logged in user
      if (userDetails && env !== 'IN' && !isMobileApp()) {
        //logged in global user and from web
        return (
          <Suspense fallback={<LoadingFallback />}>
            <AppRegister from={location?.pathname?.includes('fallback') ? {} : location} />
          </Suspense>
        );
      } else if (
        userDetails &&
        spec_dashboard_eligibility_status &&
        specility_update_status === '0'
      ) {
        //logged in user needs onboarding
        return (
          <Suspense fallback={<LoadingFallback />}>
            <SpecialityOnboarding />
          </Suspense>
        );
      } else {
        //logged in user
        return children;
      }
    } else {
      //not logged in user
      return <Navigate to="/" state={{ from: location }} />;
    }
  }
}

export default AuthRouter;
