import React, { memo } from 'react';
import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import { useCertificateList } from '../../../../Queries/QueryHooks/certificate.hook';
import { certificate_static_image } from '../../../../common/assets.common';
import useRedirect from '../../../../components/customHooks/useRedirect';
import { routeNames } from '../../../../router/constants';

function DoctorsAchievement() {
  const { data: certList, isLoading: loader } = useCertificateList(() => {});
  const { redirectTo } = useRedirect();
  if (!certList || !certList?.pages?.length) return null;

  const data = certList?.pages[0]?.data?.data[0];
  if (!data) return null;
  return (
    <div className="rounded-3 bg-extra-light p-3 p-sm-4 d-flex flex-column observership-card-1 gap-2">
      <h2 className="fs-16 text-black fw-semibold mb-2">
        Doctors <span className="text-primary">Achievement</span>
      </h2>
      <div className="d-flex rounded d-flex align-items-center gap-3 mb-2">
        {(data?.file || data?.image) && (
          <LazyImage
            src={data?.file?.includes('.pdf') ? data?.image : data?.file}
            alt="certificate"
            className="w-100 h-auto object-fit-cover"
            extraClass="w-100 h-auto rounded overflow-hidden"
            fallback_image={certificate_static_image}
          />
        )}
      </div>
      <a
        href="javascript:void(0)"
        className="ms-auto d-inline-flex gap-2"
        onClick={() => redirectTo(routeNames.certificate.landing)}
      >
        View All <i className="flaticon flaticon-next fs-4"></i>
      </a>
    </div>
  );
}

export default memo(DoctorsAchievement);
