import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import { BANNER_LOAD } from '../tracking/constants';
import { onBannerLoad, onCardClick } from '../tracking/UserActions';
import useNetwork from '../customHooks/useNetwork';

const ImageBannerPlayer = ({
  hours = 0,
  minutes = 0,
  seconds = 0,
  url = '',
  playing = false,
  onEnded,
  onDuration,
  onError,
  className = '',
  campaign_id = 0,
  creative_id = 0
}) => {
  const userDetails = useSelector((state) => state.common.user_details);
  const image_container_ref = useRef();
  const [paused, setPaused] = React.useState(playing);
  const [over, setOver] = React.useState(false);
  const [[h, m, s], setTime] = React.useState([hours, minutes, seconds]);
  const [imgUrl, setImgUrl] = React.useState('');
  const { trackActivityEvent } = useTracker();
  const tick = () => {
    if (paused || over) return;
    if (h === 0 && m === 0 && s === 0) setOver(true);
    else if (m === 0 && s === 0) {
      setTime([h - 1, 59, 59]);
    } else if (s == 0) {
      setTime([h, m - 1, 59]);
    } else {
      setTime([h, m, s - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
    setPaused(false);
    setOver(false);
  };

  React.useEffect(() => {
    if (over) {
      reset();
      onEnded();
    }
  }, [over]);

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  React.useEffect(() => {
    setPaused(playing);
  }, [playing]);
  const connection = useNetwork();
  const getModifiedImageUrlBKP = useMemo(
    () => () => {
      // let transformation = `?tr=pr=true,c-at_max`;

      let elementOffsetWidth = image_container_ref?.current?.offsetWidth;
      let elementOffsetHeight = image_container_ref?.current?.offsetHeight;

      switch (connection.effectiveType) {
        case '4g':
          elementOffsetHeight *= 3;
          elementOffsetWidth *= 3;
          break;
        case '3g':
          elementOffsetHeight *= 2;
          elementOffsetWidth *= 2;
          break;
        default:
          break;
      }
      let transformation =
        elementOffsetWidth > 0 || elementOffsetHeight > 0
          ? `&tr=${elementOffsetWidth > 0 && 'w-' + elementOffsetWidth}${
              elementOffsetHeight > 0 ? ',h-' + elementOffsetHeight : ',h-auto'
            },pr=true,c-at_max`
          : '';

      // console.log('inside image banner player', transformation, url);
      if (url && typeof url == 'string' && !url.includes('https')) {
        let splittedImg = url?.split('/');
        let path_extracted_image = splittedImg[splittedImg?.length - 1];
        const cfImageUrl = `https://ik.imagekit.io/${process.env.REACT_APP_IMAGEKIT_ID}/${path_extracted_image}${transformation}&cid=${campaign_id}&umid=${userDetails.user_master_id}&crid=${creative_id}`;
        // console.log('inside image banner player in if', path_extracted_image, cfImageUrl);
        return cfImageUrl;
      } else if (url && typeof url == 'string' && url.includes('https://storage.googleapis.com/')) {
        const temp_image_name = url.replace('https://storage.googleapis.com/', '');
        const cfImageUrl = `https://ik.imagekit.io/${process.env.REACT_APP_IMAGEKIT_ID}/${temp_image_name}${transformation}&cid=${campaign_id}&umid=${userDetails.user_master_id}&crid=${creative_id}`;
        // console.log('inside image banner player in else if', temp_image_name, cfImageUrl);
        return cfImageUrl;
        // setImgUrl(cfImageUrl);
      } else {
        return url;
        // setImgUrl(url);
      }
    },
    [url]
  );

  const getModifiedImageUrl = useCallback(() => {
    var baseURL = url?.replace(
      'https://storage.googleapis.com/medwiki',
      'https://bnr-cdn.clirnet.com/'
    );

    if (baseURL.includes('?')) {
      baseURL = `${baseURL}&cid=${campaign_id}&umid=${userDetails.user_master_id}`;
    } else {
      baseURL = `${baseURL}?cid=${campaign_id}&umid=${userDetails.user_master_id}`;
    }
    switch (connection.effectiveType) {
      case '4g':
        return `${baseURL?.replace('_2x.', '_3x.')}`;

      default:
        return baseURL;
    }
  }, [url]);

  React.useEffect(() => {
    setImgUrl(getModifiedImageUrl());
  }, [url]);

  React.useEffect(() => {
    setImgUrl(getModifiedImageUrl());
  }, [onEnded]);

  React.useEffect(() => {
    onDuration(`${seconds - s.toString().padStart(2, '0')}`);
  }, [h, m, s]);

  function handleLoad() {
    trackActivityEvent(
      BANNER_LOAD,
      onBannerLoad(campaign_id)
      // true
    );
  }

  return (
    <>
      {imgUrl && (
        <img
          src={imgUrl}
          alt="banner image"
          importance="high"
          className={className ?? ''}
          style={{ width: '100%' }}
          onLoad={handleLoad}
          onError={onError}
          ref={image_container_ref}
        ></img>
      )}
      {/* {
        <p>{`${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
          .toString()
          .padStart(2, '0')}`}</p>
      }{' '}
      <div>{over ? "Time's up!" : ''}</div>
      <button onClick={() => setPaused(!paused)}>{paused ? 'Resume' : 'Pause'}</button>
      <button onClick={() => reset()}>Restart</button> */}
    </>
  );
};
export default React.memo(ImageBannerPlayer);
